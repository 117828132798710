import React, { CSSProperties } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import { Button, Input } from "components";
import { useAuth0 } from "@auth0/auth0-react";
import { allowMailUsers } from "common/users";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0;
`;

const MenuLink = styled(NavLink)`
  text-decoration: none;
  color: black;
  font-size: 1.8rem;
  line-height: 1.4;
`;

const UserInfo = styled.div`
  margin-top: 0.5rem;
  font-style: italic;
`;

interface Menu {}

export const Menu: React.FC<Menu> = () => {
  const { t } = useTranslation("entity");
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const activeStyle: CSSProperties = {
    fontWeight: "bold",
    letterSpacing: "1px",
  };
  return (
    <>
      <MenuContainer>
        <MenuLink activeStyle={activeStyle} to="/" exact>
          Home
        </MenuLink>
        <MenuLink activeStyle={activeStyle} to="/about" exact>
          About
        </MenuLink>
        <MenuLink activeStyle={activeStyle} to="/sigla" exact>
          Sigla
        </MenuLink>
        <MenuLink activeStyle={activeStyle} to="/persons">
          Persons
        </MenuLink>
        {isAuthenticated && (
          <MenuLink activeStyle={activeStyle} to="/institutions">
            Institutions
          </MenuLink>
        )}
        <MenuLink activeStyle={activeStyle} to="/manuscripts">
          Manuscripts
        </MenuLink>
        <MenuLink activeStyle={activeStyle} to="/universals">
          Universals
        </MenuLink>
        {isAuthenticated && allowMailUsers.indexOf(user.mail) > -1 && (
          <MenuLink activeStyle={activeStyle} to="/manuscripts-admin">
            Manuscripts <sub>admin</sub>
          </MenuLink>
        )}
        <MenuLink activeStyle={activeStyle} to="/map">
          Map
        </MenuLink>
      </MenuContainer>
      {/* <Input placeholder={t("search")} /> */}
      {isAuthenticated ? (
        <>
          <Button marginTop label="Log Out" onClick={() => logout()} />
          <UserInfo>
            logged as {user.name} <br /> ({user.email}){" "}
          </UserInfo>
        </>
      ) : (
        <Button marginTop label="Log In" onClick={() => loginWithRedirect()} />
      )}
    </>
  );
};
