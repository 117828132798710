import {
  FetchManuscriptRequestAction,
  FetchManuscriptSuccessAction,
  FetchManuscriptFailureAction,
  FETCH_MANUSCRIPT_REQUEST,
  FETCH_MANUSCRIPT_SUCCESS,
  FETCH_MANUSCRIPT_FAILURE,
} from "redux/types";
import { ManuscriptDataState } from "common/types";

const initManuscript = {
  id: "",
  institution_id: "",
  shelfmark: "",
  origin: "",
  facsimile: "",
  catalogue: "",
  digital_copy: "",
  universals: [],
  createdAt: "",
  updatedAt: "",
};

type ValidAction =
  | FetchManuscriptRequestAction
  | FetchManuscriptSuccessAction
  | FetchManuscriptFailureAction;

const manuscript = (
  state = { data: initManuscript },
  action: ValidAction
): ManuscriptDataState => {
  switch (action.type) {
    case FETCH_MANUSCRIPT_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_MANUSCRIPT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_MANUSCRIPT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default manuscript;
