import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { ValueType, OptionTypeBase } from "react-select";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  ManuscriptUniversalBinding,
  Person,
  PersonsDataState,
  Universal,
  UniversalsDataState,
} from "common/types";
import { Button, LinkButton } from "components";
import { Card } from "./Card";
import { Row } from "./Row";
import { AttributeValue } from "./AttributeValue";
import { findPersonById, findUniversalById } from "utils";

const StyledLink = styled(Link)`
  text-decoration: none;
  text-align: left;
  width: 100%;
  :visited {
    color: blue;
  }
`;
const AttributeHeading = styled.h4`
  margin-right: 0.5rem;
  white-space: nowrap;
  width: 14rem;
`;
interface ManuscriptDetailUniversalCard {
  manuscriptUniversalBinding: ManuscriptUniversalBinding;
  persons: PersonsDataState;
  canDelete?: boolean;
  onDelete?: (universalId: string) => void;
  selectedUniversal?: ValueType<OptionTypeBase>;
  universals: UniversalsDataState;
}
const pageEntity = "manuscriptUniversalBinding";
export const ManuscriptDetailUniversalCard: React.FC<ManuscriptDetailUniversalCard> = ({
  manuscriptUniversalBinding,
  persons,
  universals,
  canDelete,
  onDelete,
}) => {
  const { t } = useTranslation(pageEntity);
  const [scribe, setScribe] = useState<Person>();
  const [universal, setUniversal] = useState<Universal>();

  const {
    universal_id,
    title, 
    foliation,
    author_names,
    author_ids, 
    scribe_id,
    scribe_name,
    scribe_note,
    scribe_date,
  } = manuscriptUniversalBinding;

  /*
  useEffect(() => {
    setScribe(findPersonById(scribeId, persons.data));
  }, [scribeId, persons.data]);
  useEffect(() => {
    setUniversal(findUniversalById(universal_id, universals.data));
  }, [universal_id, universals.data]);
  */
  
  return (
    <Card>
      {canDelete && onDelete && (
        <Button actionButton onClick={(): void => onDelete(universal_id)}>
          <FaTrashAlt color="#212529" />
        </Button>
      )}
      {/* <Row>
        <AttributeHeading>{t("universalId")}</AttributeHeading>
        <StyledLink to={`/universals/${universalId}`}>{universalId}</StyledLink>
      </Row> */}
      <Row>
        <AttributeHeading>{t("title")}</AttributeHeading>
        <StyledLink to={`/universals/${universal_id}`}>
          <LinkButton>{title}</LinkButton>
        </StyledLink>
      </Row>
      <Row>
        <AttributeHeading>{t("authorName")}</AttributeHeading>
        {author_ids.map((author, key) => <StyledLink to={`/persons/${author.id}`}>
          <LinkButton>{author.name}</LinkButton>
        </StyledLink>)}
      </Row>
      <Row>
        <AttributeHeading>{t("foliation")}</AttributeHeading>
        <AttributeValue>{foliation}</AttributeValue>
      </Row>
      <Row>
        <AttributeHeading>{t("scribeName")}</AttributeHeading>
        {scribe_id ? (
          <StyledLink to={`/persons/${scribe_id}`}>
            <LinkButton>{scribe_name}</LinkButton>
          </StyledLink> ) : 
          <AttributeValue>{scribe_name}</AttributeValue>
        }
      </Row>
      <Row>
        <AttributeHeading>{t("scribeNote")}</AttributeHeading>
        <AttributeValue>{scribe_note}</AttributeValue>
      </Row>
      <Row>
        <AttributeHeading>{t("scribeDate")}</AttributeHeading>
        <AttributeValue>{scribe_date}</AttributeValue>
      </Row>
    </Card>
  );
};

ManuscriptDetailUniversalCard.defaultProps = {
  onDelete: (universalId: string): void => {
    console.log("Please define onDelete action for Manuscript-Universal Card");
  },
};
