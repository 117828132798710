import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";

const StyledArea = styled.textarea`
  margin: 0.6rem 0;
  font-family: "LinLibertine";
  padding: 0.6rem;
`;

interface TextArea {
  register?: ReturnType<typeof useForm>["register"];
  required?: boolean;
  name?: string;
  readonly?: boolean;
  placeholder?: string;
}
export const TextArea: React.FC<TextArea> = ({
  register,
  required,
  name,
  readonly,
  placeholder,
}) => {
  return (
    <StyledArea
      ref={register && register({ required: required && required })}
      name={name}
      disabled={readonly}
      placeholder={placeholder}
      rows={10}
      cols={60}
    />
  );
};
