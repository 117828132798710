import styled from "styled-components";

interface EntityDetailColumn {
  attr?: boolean;
}
export const EntityDetailColumn = styled.div<EntityDetailColumn>`
  display: flex;
  width: ${({ attr }) => (attr ? "16rem" : "43rem")};
  /* align-items: center; */
  font-size: 1.6rem;
  /* line-height: 1.6; */
`;
