import React from "react";
import styled from "styled-components";

import lindat from "assets/logos/lindat-clariah-cz-black-lines.png";
import memori from "assets/logos/memori.png";
import institute from "assets/logos/f_zakladni_text_vedle_velky_cb.jpg";
import ministri from "assets/logos/msmt_logo_text_grey_eng.png";

const Foot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem 0;
  flex-shrink: 0;
`;
const ImageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
`;
interface Image {
  width?: number;
}
const Image = styled.img<Image>`
  width: ${({ width }) => (width ? `${width}rem` : "14rem")};
  margin: 0 1rem;
  filter: grayscale(100%);
`;
const TextBox = styled.p`
  width: 70rem;
  text-align: center;
`;
interface Footer {}
export const Footer: React.FC<Footer> = () => {
  return (
    <Foot>
      <p>
        © The Institute of Philosophy of the Czech Academy of Sciences | UniQ
        2023
      </p>
      <ImageBox>
        <Image src={lindat} width={12} />
        <Image src={memori} width={18} />
        <Image src={institute} width={18} />
        <Image src={ministri} width={16} />
      </ImageBox>
      <TextBox>
        The Lindat/CLARIAH-CZ project (LM2018101 and
        CZ.02.1.01/0.0/0.0/16_013/0001781; formerly LM2010013, LM2015071) is
        fully supported by the Ministry of Education, Youth, and Sports of the
        Czech Republic under the programme LM of "Large Infrastructures".
      </TextBox>
    </Foot>
  );
};
