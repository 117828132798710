import {
  FetchInstitutionRequestAction,
  FetchInstitutionSuccessAction,
  FetchInstitutionFailureAction,
  FETCH_INSTITUTION_REQUEST,
  FETCH_INSTITUTION_SUCCESS,
  FETCH_INSTITUTION_FAILURE,
} from "redux/types";
import { InstitutionDataState } from "common/types";

type ValidAction =
  | FetchInstitutionRequestAction
  | FetchInstitutionSuccessAction
  | FetchInstitutionFailureAction;

const initInstitution = {
  id: "",
  name: "",
  link: "",
  gps: "",
  grid: "",
  wikidata: "",
  gnd: "",
  createdAt: "",
  updatedAt: "",
};
const institution = (
  state = { data: initInstitution },
  action: ValidAction
): InstitutionDataState => {
  switch (action.type) {
    case FETCH_INSTITUTION_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_INSTITUTION_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_INSTITUTION_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default institution;
