import styled from "styled-components";

interface ButtonRow {
  alignRight?: boolean;
}
export const ButtonRow = styled.div<ButtonRow>`
  /* background-color: grey; */
  margin-bottom: 1rem;
  display: flex;
  flex-grow: 1;
  justify-content: ${({ alignRight }) =>
    alignRight ? "flex-end" : "flex-start"};
`;
