import React, { useMemo, useEffect, ReactNode, useState } from "react";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import { FaTrashAlt, FaPencilAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Cell } from "react-table";

import { allowMailUsers } from "common/users";
import { fetchPersons } from "redux/actions/entitiesActions";
import { SearchFormData, PersonsDataState } from "common/types";
import {
  Table,
  SearchForm,
  Button,
  LinkButton,
  PageTitle,
  Submit,
  ButtonRow,
} from "components";
import { deleteEntity } from "api/deleteEntity";
import { useAuth0 } from "@auth0/auth0-react";

interface PersonPage {
  persons: PersonsDataState;
  fetchPersons: (formData?: SearchFormData) => void;
}

const pageEntity = "person";

const PersonPage: React.FC<PersonPage> = ({ persons, fetchPersons }) => {
  const { t } = useTranslation([pageEntity, "submit", "entity"]);
  const [idToDelete, setIdToDelete] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  
  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);

  const data = useMemo(() => (persons.isFetching ? [] : persons.data), [
    persons,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: t("id"),
        accessor: "id",
        show: false,
        Cell: ({ row }: Cell): ReactNode => {
          const entityId = row.values.id;
          return (
            <Link to={`/${pageEntity}s/${entityId}`}>
              <LinkButton>{entityId.substring(0, 5)}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("name"),
        accessor: "name",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <Link to={`/${pageEntity}s/${row.values.id}`}>
              <LinkButton>{row.values.name}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("note"),
        accessor: "note",
      },
      {
        Header: t("entity:actions"),
        Cell: isAuthenticated && allowMailUsers.indexOf(user.email) > -1 ? (
          ({ row }: Cell): ReactNode => {
            return (
              <>
                <Link to={`/${pageEntity}s/${row.values.id}/edit`}>
                  <Button actionButton name="edit-button">
                    <FaPencilAlt color="#212529" />
                  </Button>
                </Link>
                <Button
                  actionButton
                  name="delete-button"
                  onClick={(): void => triggerSubmit(row.values.id)}
                >
                  <FaTrashAlt color="#212529" />
                </Button>
              </>
            );
          }
        ) : (
          <div />
        ),
      },
    ],
    [t, isAuthenticated]
  );

  const onSearchSubmit = (formData: SearchFormData): void => {
    fetchPersons(formData);
  };

  const triggerSubmit = (id: string) => {
    setIdToDelete(id);
    setShowSubmit(true);
  };

  const deleteEntityById = (id: string): void => {
    deleteEntity(pageEntity, id).then(() => fetchPersons());
  };

  const submitCallback = (): void => {
    setShowSubmit(false);
    deleteEntityById(idToDelete);
    setIdToDelete("");
  };

  return (
    <>
      <PageTitle>{t("pageTitle")}</PageTitle>
      <SearchForm
        attributes={["name"]}
        entity={pageEntity}
        onSearchSubmit={onSearchSubmit}
        onSearchReset={(): void => fetchPersons()}
        readonly={persons.isFetching}
      />
      <ButtonRow>
        {isAuthenticated && <Link to={`/${pageEntity}s/${uuidv4()}/new`}>
          <Button>{t("entity:new")}</Button>
        </Link>}
      </ButtonRow>

      <Table
        data={data}
        columns={columns}
        showLoading={persons && persons.isFetching}
      />
      <Submit
        title={t("delete.title")}
        text={t("delete.text", { id: idToDelete })}
        onSubmit={submitCallback}
        onCancel={(): void => setShowSubmit(false)}
        show={showSubmit}
      />
    </>
  );
};

const mapStateToProps = ({ persons }: StateFromProps): StateToProps => ({
  persons,
});

export default connect(mapStateToProps, {
  fetchPersons,
})(PersonPage);

interface StateFromProps {
  persons: PersonsDataState;
}

interface StateToProps {
  persons: PersonsDataState;
}
