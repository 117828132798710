import React from "react";
import styled from "styled-components";
import debounce from "debounce";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { SearchFormData } from "common/types";
import { Button, Input } from "components";


const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 1rem;
  /* border: 1px dotted black; */
`;

const FieldsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 1rem;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 0.5rem; */
  margin-right: 2rem;
`;

const Label = styled.h4``;

interface SearchForm {
  attributes: string[];
  entity: string;
  onSearchSubmit: Function;
  onSearchReset: Function;
  readonly?: boolean;
}
export const SearchForm: React.FC<SearchForm> = ({
  attributes,
  entity,
  onSearchSubmit,
  onSearchReset,
  readonly,
}) => {
  const { t } = useTranslation([`${entity}`, "searchForm"]);
  const { register, handleSubmit, reset } = useForm<SearchFormData>();
  const onSubmit = (data: Record<string, string>): void => {
    onSearchSubmit(data)
  };
  const onReset = () => {
    reset();
    onSearchReset();
  };

  // {/*onChange={handleSubmit(onSubmit)}> */}
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormContainer>
        <FieldsContainer>
          {attributes.map((attribute, key) => (
            <Field key={key}>
              <Label>{t(`${attribute}`)}</Label>
              <Input type="text" register={register} name={attribute} />
            </Field>
          ))}
          <Button
            light
            label={t("searchForm:search")}
            onClick={handleSubmit(onSubmit)}
          />
          <Button
            light
            label={t("searchForm:reset")}
            onClick={() => onReset()}
          />
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};
