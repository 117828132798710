import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalCard,
} from "components";

const Text = styled.p`
  font-size: 1.8rem;
`;
interface Submit {
  title?: string;
  text?: string;
  show: boolean;
  onSubmit: Function;
  onCancel: Function;
}
export const Submit: React.FC<Submit> = ({
  title,
  text,
  show,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation("submit");
  return (
    <>
      <Modal onClose={(): void => onCancel()} showModal={show}>
        <ModalCard>
          <ModalHeader onClose={(): void => onCancel()} title={title} />
          <ModalContent>
            <Text>{text}</Text>
          </ModalContent>
          <ModalFooter>
            <Button type="button" onClick={(): void => onSubmit()}>
              {t("submit")}
            </Button>
            <Button
              type="button"
              light
              onClick={(): void => onCancel(false)}
              marginLeft
            >
              {t("cancel")}
            </Button>
          </ModalFooter>
        </ModalCard>
      </Modal>
    </>
  );
};
