import { Dispatch, AnyAction } from "redux";

import { getEntity } from "api/getEntity";
import {
  FETCH_ENTITY_REQUEST,
  FETCH_ENTITY_SUCCESS,
  FETCH_ENTITY_FAILURE,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_REQUEST,
  FETCH_PERSON_FAILURE,
  FETCH_UNIVERSAL_REQUEST,
  FETCH_UNIVERSAL_SUCCESS,
  FETCH_UNIVERSAL_FAILURE,
  FETCH_MANUSCRIPT_REQUEST,
  FETCH_MANUSCRIPT_SUCCESS,
  FETCH_MANUSCRIPT_FAILURE,
  FETCH_INSTITUTION_REQUEST,
  FETCH_INSTITUTION_SUCCESS,
  FETCH_INSTITUTION_FAILURE,
} from "redux/types";
import {
  Person,
  Institution,
  Universal,
  Manuscript,
  ManuscriptsUniversal,
  Scribed,
  Authored
} from "common/types";

export const fetchEntityRequest = (payload?: {}): AnyAction => ({
  type: FETCH_ENTITY_REQUEST,
  payload,
});
export const fetchEntitySuccess = (
  payload: Person | Institution | Universal | Manuscript | ManuscriptsUniversal | Authored | Scribed
): AnyAction => ({
  type: FETCH_ENTITY_SUCCESS,
  payload,
});
export const fetchEntityFailure = (payload: Error): AnyAction => ({
  type: FETCH_ENTITY_FAILURE,
  payload,
  error: true,
});

export const fetchEntity = (entityType: string, id: string) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchEntityRequest());
    try {
      const data = await getEntity(entityType, id);
      return dispatch(fetchEntitySuccess(data));
    } catch (e) {
      dispatch(fetchEntityFailure(e));
      throw e;
    }
  };
};

export const fetchPersonRequest = (payload?: {}): AnyAction => ({
  type: FETCH_PERSON_REQUEST,
  payload,
});
export const fetchPersonSuccess = (payload: Person): AnyAction => ({
  type: FETCH_PERSON_SUCCESS,
  payload,
});
export const fetchPersonFailure = (payload: Error): AnyAction => ({
  type: FETCH_PERSON_FAILURE,
  payload,
  error: true,
});
export const fetchPerson = (id: string) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchPersonRequest());
    try {
      const data = await getEntity("person", id);
      return dispatch(fetchPersonSuccess(data as Person));
    } catch (e) {
      dispatch(fetchPersonFailure(e));
      throw e;
    }
  };
};

export const fetchUniversalRequest = (payload?: {}): AnyAction => ({
  type: FETCH_UNIVERSAL_REQUEST,
  payload,
});
export const fetchUniversalSuccess = (payload: Universal): AnyAction => ({
  type: FETCH_UNIVERSAL_SUCCESS,
  payload,
});
export const fetchUniversalFailure = (payload: Error): AnyAction => ({
  type: FETCH_UNIVERSAL_FAILURE,
  payload,
  error: true,
});
export const fetchUniversal = (id: string) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchUniversalRequest());
    try {
      const data = await getEntity("universal", id);
      return dispatch(fetchUniversalSuccess(data as Universal));
    } catch (e) {
      dispatch(fetchUniversalFailure(e));
      throw e;
    }
  };
};

export const fetchManuscriptRequest = (payload?: {}): AnyAction => ({
  type: FETCH_MANUSCRIPT_REQUEST,
  payload,
});
export const fetchManuscriptSuccess = (payload: Manuscript): AnyAction => ({
  type: FETCH_MANUSCRIPT_SUCCESS,
  payload,
});
export const fetchManuscriptFailure = (payload: Error): AnyAction => ({
  type: FETCH_MANUSCRIPT_FAILURE,
  payload,
  error: true,
});
export const fetchManuscript = (id: string) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchManuscriptRequest());
    try {
      const data = await getEntity("manuscript", id);
      return dispatch(fetchManuscriptSuccess(data as Manuscript));
    } catch (e) {
      dispatch(fetchManuscriptFailure(e));
      throw e;
    }
  };
};

export const fetchInstitutionRequest = (payload?: {}): AnyAction => ({
  type: FETCH_INSTITUTION_REQUEST,
  payload,
});
export const fetchInstitutionSuccess = (payload: Institution): AnyAction => ({
  type: FETCH_INSTITUTION_SUCCESS,
  payload,
});
export const fetchInstitutionFailure = (payload: Error): AnyAction => ({
  type: FETCH_INSTITUTION_FAILURE,
  payload,
  error: true,
});
export const fetchInstitution = (id: string) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchInstitutionRequest());
    try {
      const data = await getEntity("institution", id);
      return dispatch(fetchInstitutionSuccess(data as Institution));
    } catch (e) {
      dispatch(fetchInstitutionFailure(e));
      throw e;
    }
  };
};
