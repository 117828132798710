import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { ValueType, OptionTypeBase } from "react-select";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  iOption,
  ManuscriptUniversalBinding,
  PersonsDataState,
  UniversalsDataState,
} from "common/types";
import { Input, Button, DropDown, Toast } from "components";
import { Card } from "./Card";
import { Row } from "./Row";
import { AttributeValue } from "./AttributeValue";
import { toast } from "react-toastify";

const AttributeHeading = styled.h4`
  margin-right: 0.5rem;
  white-space: nowrap;
  width: 14rem;
`;
interface ManuscriptDetailUniversalCardNew {
  onAdd: (universal: ManuscriptUniversalBinding) => void;
  // selectedUniversal?: ValueType<OptionTypeBase>;
  persons: PersonsDataState;
  universals: UniversalsDataState;
  currentUniversals: ManuscriptUniversalBinding[];
}
const pageEntity = "manuscriptUniversalBinding";

const ManuscriptDetailUniversalCardNew: React.FC<ManuscriptDetailUniversalCardNew> = ({
  onAdd,
  // selectedUniversal,
  persons,
  universals,
  currentUniversals,
}) => {
  const { t } = useTranslation(pageEntity);

  const [selectedUniversal, setSelectedUniversal] = useState<
    ValueType<OptionTypeBase>
  >();
  const [selectedAuthors, setSelectedAuthors] = useState<
    ValueType<OptionTypeBase>
  >(null);
  const [selectedScribe, setSelectedScribe] = useState<
    ValueType<OptionTypeBase>
  >();
  const [dropDownUniversals, setDropDownUniversals] = useState([{}]);
  const [dropDownScribes, setDropDownScribes] = useState([{}]);
  const [foliation, setFoliation] = useState("");
  const [title, setTitle] = useState("");
  const [scribeNote, setScribeNote] = useState("");
  const [scribeDate, setScribeDate] = useState("");

  useEffect(() => {
    const scribes = persons.data.map((person) => {
      return { label: person.name, value: person.id };
    });
    setDropDownScribes(scribes);
  }, [persons.data]);

  const getCurrentUniversalIds = (): string[] => {
    return (
      currentUniversals &&
      currentUniversals.map((currentUnivesal) => currentUnivesal.universal_id)
    );
  };

  useEffect(() => {
    const currentUniversalIds = getCurrentUniversalIds();
    const dropUniversalsFilter = universals.data.filter((universal) => {
      if (currentUniversalIds && currentUniversalIds.length > 0) {
        const usedIds = currentUniversalIds.filter(
          (currentUniversalId) => currentUniversalId === universal.id
        );
        if (usedIds.length === 0) {
          return universal;
        }
      } else {
        return universal;
      }
    });
    const dropUniversals = dropUniversalsFilter.map((universal) => {
      return { value: universal.id, label: universal.title };
    });
    setDropDownUniversals(dropUniversals);
  }, [universals.data, currentUniversals]);

  const addCallback = () => {
    if (!selectedUniversal) {
      toast.warning("Please select universal title.");
    } else if (!selectedScribe) {
      toast.warning("Pleas select scribe.");
    } else {
      onAdd({
        universal_id: (selectedUniversal as iOption).value,
        foliation: foliation,
        author_ids: [],
        author_names: "",
        title: "",
        scribe_id: (selectedScribe as iOption).value,
        scribe_name: "",
        scribe_note: scribeNote,
        scribe_date: scribeDate,
      });
      setFoliation("");
      setScribeNote("");
      setScribeDate("");
      setSelectedAuthors(null);
      setSelectedScribe(null);
      setSelectedUniversal(null);
    }
  };

  return (
    <>
      <Card>
        <Button
          actionButton
          type="button"
          name="add-universal"
          onClick={onAdd && addCallback}
        >
          <FaPlus color="#212529" />
        </Button>

        {/* <Row>
          <AttributeHeading>{t("universalId")}</AttributeHeading>
          <AttributeValue>
            {selectedUniversal && (selectedUniversal as iOption).value}
          </AttributeValue>
        </Row> */}

        <Row>
          <AttributeHeading>{t("title")}</AttributeHeading>
          <DropDown
            value={selectedUniversal}
            onChange={(selectedUniversal: ValueType<OptionTypeBase>) =>
              setSelectedUniversal(selectedUniversal)
            }
            options={dropDownUniversals}
          />
        </Row>
        <Row>
          <AttributeHeading>{t("foliation")}</AttributeHeading>
          <Input
            name="foliation"
            onChange={(e) => setFoliation(e.target.value)}
            value={foliation}
            underline
            type="text"
          />
        </Row>
        <Row margin>
          <AttributeHeading>{t("scribe")}</AttributeHeading>
          <DropDown
            value={selectedScribe}
            onChange={(selectedScribe: ValueType<OptionTypeBase>) =>
              setSelectedScribe(selectedScribe)
            }
            options={dropDownScribes}
          />
        </Row>
        <Row>
          <AttributeHeading>{t("scribeNote")}</AttributeHeading>
          <Input
            name="scribeNote"
            onChange={(e) => setScribeNote(e.target.value)}
            value={scribeNote}
            underline
            type="text"
          />
        </Row>
        <Row>
          <AttributeHeading>{t("scribeDate")}</AttributeHeading>
          <Input
            name="scribeDate"
            onChange={(e) => setScribeDate(e.target.value)}
            value={scribeDate}
            underline
            type="text"
          />
        </Row>
      </Card>
      <Toast />
    </>
  );
};

export default ManuscriptDetailUniversalCardNew;

ManuscriptDetailUniversalCardNew.defaultProps = {
  onAdd: (): void => {
    console.log("Please define onAdd function");
  },
};
