import { api } from "./api";
import { Person, Institution, Universal, Manuscript } from "common/types";
import store from "redux/store";

export const updateEntity = async (
  entityType: string,
  entity: Person | Institution | Universal | Manuscript
): Promise<Person | Institution | Universal | Manuscript> => {
  const authToken = store.getState().token;
  const config = {
    headers: { Authorization: `Bearer ${authToken}` },
  };
  console.log(entity);
  try {
    const response = await api.put(
      `/${entityType}s/${entity.id}`,
      entity,
      config
    );
    return await response.data;
  } catch (err) {
    // console.log(err);
    throw err;
  }
};
