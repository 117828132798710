import { applyMiddleware, createStore, compose, CombinedState } from "redux";
import thunk from "redux-thunk";
import { Store } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import rootReducer from "./reducers";
import {
  FetchEntitiesRequestAction,
  FetchEntitiesSuccessAction,
  FetchEntitiesFailureAction,
  FetchEntityRequestAction,
  FetchEntitySuccessAction,
  FetchEntityFailureAction,
  FetchPersonSuccessAction,
  FetchPersonRequestAction,
  FetchPersonFailureAction,
  FetchUniversalSuccessAction,
  FetchUniversalRequestAction,
  FetchUniversalFailureAction,
  FetchManuscriptSuccessAction,
  FetchManuscriptRequestAction,
  FetchManuscriptFailureAction,
  FetchInstitutionSuccessAction,
  FetchInstitutionRequestAction,
  FetchInstitutionFailureAction,
  FetchPersonsRequestAction,
  FetchPersonsSuccessAction,
  FetchPersonsFailureAction,
  FetchUniversalsSuccessAction,
  FetchUniversalsRequestAction,
  FetchUniversalsFailureAction,
  FetchManuscriptsSuccessAction,
  FetchManuscriptsRequestAction,
  FetchManuscriptsFailureAction,
  FetchInstitutionsSuccessAction,
  FetchInstitutionsRequestAction,
  FetchInstitutionsFailureAction,
  FetchManuscriptsUniversalsSuccessAction,
  FetchManuscriptsUniversalsRequestAction,
  FetchManuscriptsUniversalsFailureAction,
  AuthTokenAction,
} from "./types";

const initialState = {};

const middleWare = [thunk];

const store: Store<
  CombinedState<{ token: string }>,
  | FetchEntitiesRequestAction
  | FetchEntitiesSuccessAction
  | FetchEntitiesFailureAction
  | FetchEntityRequestAction
  | FetchEntitySuccessAction
  | FetchEntityFailureAction
  | FetchPersonRequestAction
  | FetchPersonSuccessAction
  | FetchPersonFailureAction
  | FetchUniversalSuccessAction
  | FetchUniversalRequestAction
  | FetchUniversalFailureAction
  | FetchManuscriptSuccessAction
  | FetchManuscriptRequestAction
  | FetchManuscriptFailureAction
  | FetchInstitutionSuccessAction
  | FetchInstitutionRequestAction
  | FetchInstitutionFailureAction
  | FetchPersonsRequestAction
  | FetchPersonsSuccessAction
  | FetchPersonsFailureAction
  | FetchUniversalsSuccessAction
  | FetchUniversalsRequestAction
  | FetchUniversalsFailureAction
  | FetchManuscriptsUniversalsSuccessAction
  | FetchManuscriptsUniversalsRequestAction
  | FetchManuscriptsUniversalsFailureAction
  | FetchManuscriptsSuccessAction
  | FetchManuscriptsRequestAction
  | FetchManuscriptsFailureAction
  | FetchInstitutionsSuccessAction
  | FetchInstitutionsRequestAction
  | FetchInstitutionsFailureAction
  | AuthTokenAction
> = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleWare))
);

export default store;
