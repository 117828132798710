import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import DotLoader from "react-spinners/DotLoader";
import { ValueType, OptionTypeBase } from "react-select";
import { toast } from "react-toastify";

import {
  ManuscriptDataState,
  Manuscript,
  UniversalsDataState,
  SearchFormData,
  ManuscriptUniversalBinding,
  PersonsDataState,
  UseParamsId,
  InstitutionsDataState,
  iOption,
} from "common/types";
import { fetchManuscript } from "redux/actions/entityActions";
import { fetchUniversals, fetchPersons, fetchInstitutions } from "redux/actions/entitiesActions";
import {
  EntityDetailBlockHeading as BlockHeading,
  EntityDetailRow as Row,
  EntityDetailColumn as Column,
  Input,
  Button,
  DropDown,
  ManuscriptDetailUniversalCard,
  ManuscriptDetailUniversalCardNew,
  Toast,
} from "components";
import { addEntity } from "api/addEntity";
import { updateEntity } from "api/updateEntity";
import { getDateTimeFromUtf } from "utils";

const UniversalsColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
`;
const StyledALink = styled.a`
  /* text-decoration: none; */
  :visited {
    color: blue;
  }
`;

const parseFoliation = (x: string) => {
  const tmp = x.match("[0-9]+");
  if (tmp !== null) {
    return parseInt(tmp[0])
  }else{
    return 999
  }
};

interface ManuscriptDetail {
  manuscript: ManuscriptDataState;
  fetchManuscript: (id: string) => void;
  universals: UniversalsDataState;
  fetchUniversals: (formData?: SearchFormData) => void;
  persons: PersonsDataState;
  fetchPersons: () => void;
  institutions: InstitutionsDataState;
  fetchInstitutions: () => void;
  isNew?: boolean;
  isEdit?: boolean;
}
type FormData = {
  id: string;
  institution_id: string;
  shelfmark: string;
  origin: string;
  facsimile: string;
  catalogue: string;
  digital_copy: string;
};

type SelectedInstitution = {
  value: string;
  label: string;
};

const pageEntity = "manuscript";
// Menu: Manuscript admin
const ManuscriptDetail: React.FC<ManuscriptDetail> = ({
  manuscript,
  fetchManuscript,
  universals,
  fetchUniversals,
  persons,
  fetchPersons,
  institutions, 
  fetchInstitutions,
  isNew,
  isEdit,
}) => {
  const { t } = useTranslation(pageEntity);
  const history = useHistory();
  const { id } = useParams<UseParamsId>();
  const { register, handleSubmit, setValue, reset } = useForm<FormData>();
  const [selectedUniversal, setSelectedUniversal] = useState<
    ValueType<OptionTypeBase>
  >();
  const [dropDownUniversals, setDropDownUniversals] = useState([{}]);

  const [currentUniversals, setCurrentUniversals] = useState<
    ManuscriptUniversalBinding[]
  >([]);

  const [selectedInstitution, setSelectedInstitution] = useState<
    ValueType<OptionTypeBase>
  >(null);
  const [dropDownInstitution, setDropDownInstitution] = useState([{}]);

  const {
    institution_id,
    shelfmark,
    origin,
    facsimile,
    catalogue,
    digital_copy,
    createdAt,
    updatedAt,
  } = manuscript.data;

  useEffect(() => {
    fetchUniversals();
  }, [fetchUniversals]);

  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);

  useEffect(() => {
    fetchInstitutions();
  }, [fetchInstitutions]);

  useEffect(() => {
    setCurrentUniversals(
      manuscript.data.universals &&
        manuscript.data.universals.sort((a, b) =>
          parseFoliation(a.foliation) > parseFoliation(b.foliation) ? 1 : -1
        )
    );
  }, [manuscript.data.universals]);

  useEffect(() => {
    const dropInstitutions = institutions.data.map((institution, key) => {
      return { key: key, value: institution.id, label: institution.name };
    });
    setDropDownInstitution(dropInstitutions);
  }, [institutions.data]);

  useEffect(() => {
    if (!isNew) {
      fetchManuscript(id);
    } else {
      setCurrentUniversals([]);
    }
  }, [fetchManuscript, id, isNew]);

  useEffect(() => {
    if (isNew) {
      reset();
      setValue("id", id);
    } else if (!manuscript.isFetching) {
      setValue("id", id);
      setValue("institution_id", institution_id);
      setValue("shelfmark", shelfmark);
      setValue("origin", origin);
      setValue("facsimile", facsimile);
      setValue("catalogue", catalogue);
      setValue("digital_copy", digital_copy);
    }
  }, [
    setValue,
    isNew,
    id,
    manuscript.isFetching,
    institution_id,
    shelfmark,
    origin,
    catalogue,
    facsimile,
    digital_copy,
  ]);

  const deleteUniversalBinding = (universalId: string) => {
    setCurrentUniversals(
      currentUniversals.filter(
        (currentUniversal) => currentUniversal.universal_id !== universalId
      )
    );
  };

  const addUniversal = (universal: ManuscriptUniversalBinding) => {
    currentUniversals
      ? setCurrentUniversals([universal, ...currentUniversals])
      : setCurrentUniversals([universal]);
    setSelectedUniversal(null);
  };

  const onSubmit = ({
    institution_id,
    shelfmark,
    catalogue,
    origin,
    facsimile,
    digital_copy,
  }: FormData): void => {
    /*
    if (
      !digital_copy.startsWith("http://") &&
      !digital_copy.startsWith("https://")
    ) {
      wrongDigitalCopyLinkNotify();
      return;
    }
    */
    
    const manuscriptObject = {
      id: id,
      institution_id: selectedInstitution
        ? (selectedInstitution as iOption).value
        : "",
      shelfmark: shelfmark,
      origin: origin,
      facsimile: facsimile,
      catalogue: catalogue,
      digital_copy: digital_copy,
      universals: currentUniversals,
    };
    if (isNew) {
      addEntity(pageEntity, manuscriptObject as Manuscript).then(() => {
        history.push(`/${pageEntity}s/${id}/edit`);
        createNotify();
      });
    } else {
      updateEntity(pageEntity, manuscriptObject as Manuscript).then(() =>
        saveNotify()
      );
    }
  };

  const createNotify = () => toast.dark(t("createSuccess"));
  const saveNotify = () => toast.dark(t("saveSuccess"));
  const wrongDigitalCopyLinkNotify = () =>
    toast.warning("Digital copy has to start with http:// or https://");

  return (
    <>
      {manuscript && !manuscript.isFetching ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <BlockHeading>{t("detailTitle")}</BlockHeading>
          {/* 
            <Row>
            <Column attr>
              <b>{t("id")}</b>
            </Column>
            <Column>{id}</Column>
            </Row>  
          */}
      
          {(isNew || isEdit) && (
            <Row>
            <Column attr>
              <b>{t("institution")}</b>
            </Column>
            <Column>
              <DropDown
                value={selectedInstitution}
                onChange={(selectedInstitution: ValueType<OptionTypeBase>) =>
                  { console.log(selectedInstitution)
                  setSelectedInstitution(selectedInstitution) }
                }
                options={dropDownInstitution}
              />
            </Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("shelfmark")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="shelfmark"
                  register={register}
                  required
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("shelfmark")}</b>
              </Column>
              <Column>{shelfmark}</Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("origin")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="origin"
                  register={register}
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("origin")}</b>
              </Column>
              <Column>{origin}</Column>
            </Row>
          )}
          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("facsimile")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="facsimile"
                  register={register}
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("facsimile")}</b>
              </Column>
              <Column><a href={facsimile}>{facsimile}</a></Column>
            </Row>
          )} 
          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("catalogue")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="catalogue"
                  register={register}
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("catalogue")}</b>
              </Column>
              {digital_copy != "" && digital_copy != " " ? 
              (
              <Column>
                <StyledALink href={digital_copy} target="_blank">
                  {catalogue}
                </StyledALink>
              </Column>
              ) : 
              (
              <Column>
                {catalogue}
              </Column>
              )
              }
            </Row>
          )}
          
          {(isNew || isEdit) && (
            <Row>
              <Column attr>
                <b>{t("digital_copy")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="digital_copy"
                  register={register}
                />
              </Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("universals")}</b>
              </Column>
              <UniversalsColumn>
                <ManuscriptDetailUniversalCardNew
                  persons={persons}
                  onAdd={addUniversal}
                  universals={universals}
                  currentUniversals={currentUniversals}
                />
                {currentUniversals &&
                  currentUniversals.map((universal, key) => (
                    <ManuscriptDetailUniversalCard
                      key={key}
                      persons={persons}
                      onDelete={deleteUniversalBinding}
                      manuscriptUniversalBinding={universal}
                      canDelete
                      universals={universals}
                    />
                  ))}
              </UniversalsColumn>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("universals")}</b>
              </Column>
              <UniversalsColumn>
                {currentUniversals &&
                  currentUniversals.map((universal, key) => (
                    <ManuscriptDetailUniversalCard
                      key={key}
                      persons={persons}
                      manuscriptUniversalBinding={universal}
                      universals={universals}
                    />
                  ))}
              </UniversalsColumn>
            </Row>
          )}

          {isEdit && (
            <>
              <Row>
                <Column attr>
                  <b>{t("entity:created")}</b>
                </Column>
                <Column>{getDateTimeFromUtf(createdAt)}</Column>
              </Row>
              <Row>
                <Column attr>
                  <b>{t("entity:updated")}</b>
                </Column>
                <Column>{getDateTimeFromUtf(updatedAt)}</Column>
              </Row>
            </>
          )}

          <Row>
            <Column attr>
              <b>{t("entity:permalink")}</b>
            </Column>
            <Column>{`${window.location.origin}/${pageEntity}s/${id}`}</Column>
          </Row>

          {isNew || isEdit ? (
            <Row noBorder marginTop>
              <Column attr></Column>
              <Column>
                <Link to={`/${pageEntity}s`}>
                  <Button light marginRight>
                    {t("entity:cancel")}
                  </Button>
                </Link>
                <Button type="submit">{t("entity:save")}</Button>
              </Column>
            </Row>
          ) : (
            <Row noBorder marginTop>
              <Column attr>
                <Link to={`/${pageEntity}s`}>
                  <Button>{t("entity:back")}</Button>
                </Link>
              </Column>
              <Column></Column>
            </Row>
          )}
        </form>
      ) : (
        <DotLoader
          size={70}
          color="#000"
          css={`
            margin: 20rem auto;
          `}
        />
      )}
      <Toast />
    </>
  );
};

const mapStateToProps = ({
  manuscript,
  universals,
  persons,
  institutions,
}: StateToFromProps): StateToFromProps => ({
  manuscript,
  universals,
  persons,
  institutions,
});

export default connect(mapStateToProps, {
  fetchManuscript,
  fetchUniversals,
  fetchPersons,
  fetchInstitutions,
})(ManuscriptDetail);

interface StateToFromProps {
  manuscript: ManuscriptDataState;
  universals: UniversalsDataState;
  persons: PersonsDataState;
  institutions: InstitutionsDataState;
}
