import { Dispatch, AnyAction } from "redux";

import { getEntities } from "api/getEntities";
import {
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITIES_FAILURE,
  FETCH_PERSONS_REQUEST,
  FETCH_PERSONS_SUCCESS,
  FETCH_PERSONS_FAILURE,
  FETCH_UNIVERSALS_REQUEST,
  FETCH_UNIVERSALS_SUCCESS,
  FETCH_UNIVERSALS_FAILURE,
  FETCH_MANUSCRIPTS_REQUEST,
  FETCH_MANUSCRIPTS_SUCCESS,
  FETCH_MANUSCRIPTS_FAILURE,
  FETCH_INSTITUTIONS_REQUEST,
  FETCH_INSTITUTIONS_SUCCESS,
  FETCH_INSTITUTIONS_FAILURE,
  FETCH_MANUSCRIPTS_UNIVERSALS_REQUEST,
  FETCH_MANUSCRIPTS_UNIVERSALS_SUCCESS,
  FETCH_MANUSCRIPTS_UNIVERSALS_FAILURE
} from "redux/types";
import {
  Person,
  SearchFormData,
  Institution,
  Universal,
  Manuscript,
  ManuscriptsUniversal,
  Authored,
  Scribed
} from "common/types";

export const fetchEntitiesRequest = (payload?: {}): AnyAction => ({
  type: FETCH_ENTITIES_REQUEST,
  payload,
});
export const fetchEntitiesSuccess = (
  payload:
    | Person[]
    | Institution[]
    | Universal[]
    | Manuscript[]
    | ManuscriptsUniversal[]
    | Authored[]
    | Scribed[]
): AnyAction => ({
  type: FETCH_ENTITIES_SUCCESS,
  payload,
});
export const fetchEntitiesFailure = (payload: Error): AnyAction => ({
  type: FETCH_ENTITIES_FAILURE,
  payload,
  error: true,
});
export const fetchEntities = (
  entityType: string,
  formData?: SearchFormData
) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchEntitiesRequest());
    try {
      const data = await getEntities(entityType, formData);
      return dispatch(fetchEntitiesSuccess(data));
    } catch (e) {
      dispatch(fetchEntitiesFailure(e));
      throw e;
    }
  };
};

export const fetchPersonsRequest = (payload?: {}): AnyAction => ({
  type: FETCH_PERSONS_REQUEST,
  payload,
});
export const fetchPersonsSuccess = (payload: Person[]): AnyAction => ({
  type: FETCH_PERSONS_SUCCESS,
  payload,
});
export const fetchPersonsFailure = (payload: Error): AnyAction => ({
  type: FETCH_PERSONS_FAILURE,
  payload,
  error: true,
});
export const fetchPersons = (formData?: SearchFormData) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchPersonsRequest());
    try {
      const data = await getEntities("person", formData);
      return dispatch(fetchPersonsSuccess(data as Person[]));
    } catch (e) {
      dispatch(fetchPersonsFailure(e));
      throw e;
    }
  };
};

export const fetchUniversalsRequest = (payload?: {}): AnyAction => ({
  type: FETCH_UNIVERSALS_REQUEST,
  payload,
});
export const fetchUniversalsSuccess = (payload: Universal[]): AnyAction => ({
  type: FETCH_UNIVERSALS_SUCCESS,
  payload,
});
export const fetchUniversalsFailure = (payload: Error): AnyAction => ({
  type: FETCH_UNIVERSALS_FAILURE,
  payload,
  error: true,
});
export const fetchUniversals = (formData?: SearchFormData) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchUniversalsRequest());
    try {
      const data = await getEntities("universal", formData);
      return dispatch(fetchUniversalsSuccess(data as Universal[]));
    } catch (e) {
      dispatch(fetchUniversalsFailure(e));
      throw e;
    }
  };
};

export const fetchManuscriptsUniversalsRequest = (payload?: {}): AnyAction => ({
  type: FETCH_MANUSCRIPTS_UNIVERSALS_REQUEST,
  payload,
});
export const fetchManuscriptsUniversalsSuccess = (
  payload: ManuscriptsUniversal[]
): AnyAction => ({
  type: FETCH_MANUSCRIPTS_UNIVERSALS_SUCCESS,
  payload,
});
export const fetchManuscriptsUniversalsFailure = (
  payload: Error
): AnyAction => ({
  type: FETCH_MANUSCRIPTS_UNIVERSALS_FAILURE,
  payload,
  error: true,
});
export const fetchManuscriptsUniversals = (formData?: SearchFormData) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchManuscriptsUniversalsRequest());
    try {
      const data = await getEntities("manuscripts-universal", formData);
      return dispatch(
        fetchManuscriptsUniversalsSuccess(data as ManuscriptsUniversal[])
      );
    } catch (e) {
      dispatch(fetchManuscriptsUniversalsFailure(e));
      throw e;
    }
  };
};

export const fetchManuscriptsRequest = (payload?: {}): AnyAction => ({
  type: FETCH_MANUSCRIPTS_REQUEST,
  payload,
});
export const fetchManuscriptsSuccess = (payload: Manuscript[]): AnyAction => ({
  type: FETCH_MANUSCRIPTS_SUCCESS,
  payload,
});
export const fetchManuscriptsFailure = (payload: Error): AnyAction => ({
  type: FETCH_MANUSCRIPTS_FAILURE,
  payload,
  error: true,
});
export const fetchManuscripts = (formData?: SearchFormData) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchManuscriptsRequest());
    try {
      const data = await getEntities("manuscript", formData);
      return dispatch(fetchManuscriptsSuccess(data as Manuscript[]));
    } catch (e) {
      dispatch(fetchManuscriptsFailure(e));
      throw e;
    }
  };
};

export const fetchInstitutionsRequest = (payload?: {}): AnyAction => ({
  type: FETCH_INSTITUTIONS_REQUEST,
  payload,
});
export const fetchInstitutionsSuccess = (
  payload: Institution[]
): AnyAction => ({
  type: FETCH_INSTITUTIONS_SUCCESS,
  payload,
});
export const fetchInstitutionsFailure = (payload: Error): AnyAction => ({
  type: FETCH_INSTITUTIONS_FAILURE,
  payload,
  error: true,
});
export const fetchInstitutions = (formData?: SearchFormData) => {
  return async (dispatch: Dispatch): Promise<AnyAction> => {
    dispatch(fetchInstitutionsRequest());
    try {
      const data = await getEntities("institution", formData);
      return dispatch(fetchInstitutionsSuccess(data as Institution[]));
    } catch (e) {
      dispatch(fetchInstitutionsFailure(e));
      throw e;
    }
  };
};
