import React, { ChangeEvent, KeyboardEventHandler } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

import { globalBlack } from "common/constants";

interface StyledInput {
  underline?: boolean;
}
const InputWrap = styled.div`
  width: 100%;
  position: relative;
`;
const StyledInput = styled.input<StyledInput>`
  border-radius: "0";
  border: ${({ underline }) =>
    underline ? "none" : `1px solid ${globalBlack}`};
  border-bottom: ${({ underline }) =>
    underline ? `1px solid ${globalBlack}` : ""};
  outline: none;
  padding: ${({ underline }) => (underline ? "0" : "0 0.7rem")};
  height: 3rem;
  width: 100%;
  font-family: "LinLibrtine";
  font-size: 1.4rem;
`;
const Asterisk = styled.span`
  ::after {
    content: " *";
    color: #e32;
    position: absolute;
    margin: -0.2rem 0rem 0rem -2rem;
    font-size: 3rem;
    padding: 0 0.5rem 0 0;
  }
`;
interface Input {
  type?: string;
  register?: ReturnType<typeof useForm>["register"];
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  // onKeyPress?: (e: KeyboardEventHandler<HTMLInputElement>) => void;
  name?: string;
  readonly?: boolean;
  defaultValue?: number;
  placeholder?: string;
  underline?: boolean;
  value?: string;
}
export const Input: React.FC<Input> = ({
  type,
  register,
  required,
  name,
  readonly,
  defaultValue,
  onChange,
  // onKeyPress,
  placeholder,
  underline,
  value,
}) => {
  return (
    <InputWrap>
      <StyledInput
        type={type}
        ref={register && register({ required: required && required })}
        name={name}
        disabled={readonly}
        defaultValue={defaultValue}
        onChange={onChange && onChange}
        // onKeyPress={onKeyPress}
        placeholder={placeholder}
        underline={underline}
        value={value && value}
        required={required}
      />
      {required && <Asterisk />}
    </InputWrap>
  );
};
