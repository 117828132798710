import { createGlobalStyle } from "styled-components";

import LinLibertine_Rah from "assets/fonts/LinLibertine_Rah.ttf";
import LinLibertine_RBah from "assets/fonts/LinLibertine_RBah.ttf";
import LinLibertine_RBIah from "assets/fonts/LinLibertine_RBIah.ttf";
import LinLibertine_RIah from "assets/fonts/LinLibertine_RIah.ttf";

export const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
    font-size: 62.5%;
  }
  body {
    /* font-family: 'Nunito', sans-serif; */
    @font-face {
      font-family: "LinLibertine";
      src: url("${LinLibertine_Rah}") format("truetype");
      font-style: normal;
      font-weight: normal;
    }
    @font-face {
      font-family: "LinLibertine";
      src: url("${LinLibertine_RBah}") format("truetype");
      font-style: normal;
      font-weight: bold;
    }
    @font-face {
      font-family: "LinLibertine";
      src: url("${LinLibertine_RBIah}") format("truetype");
      font-style: italic;
      font-weight: bold;
    }
    @font-face {
      font-family: "LinLibertine";
      src: url("${LinLibertine_RIah}") format("truetype");
      font-style: italic;
      font-weight: normal;
    }
    font-family: "LinLibertine";
    letter-spacing: .4px;
    line-height: 1.3;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    font-size: 1.6rem;
  }
  *, *::after, *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  `;
