import styled from "styled-components";

const LinkButton = styled.button`
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.6rem;
  color: blue;
  cursor: pointer;
  outline: none;
  font-family: "LinLibertine";
  text-align: left;
`;

export default LinkButton;
