import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import App from "./App";
import "i18n";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root"),
// );
ReactGA.initialize('UA-49354205-7');

const rootElement = document.getElementById("root");

if (rootElement) {
  rootElement.dataset.mode = process.env.MODE;
  ReactDOM.render(<App />, rootElement);
}
