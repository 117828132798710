import React, { useEffect, useMemo, ReactNode, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FaPencilAlt, FaTrashAlt, FaExternalLinkAlt } from "react-icons/fa";
import { Cell } from "react-table";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { SearchFormData, InstitutionsDataState } from "common/types";
import {
  SearchForm,
  Table,
  PageTitle,
  LinkButton,
  Button,
  Submit,
  ButtonRow,
} from "components";
import { allowMailUsers } from "common/users";
import { fetchInstitutions } from "redux/actions/entitiesActions";
import { deleteEntity } from "api/deleteEntity";
import { useAuth0 } from "@auth0/auth0-react";

interface InstitutionPage {
  institutions: InstitutionsDataState;
  fetchInstitutions: (formData?: SearchFormData) => void;
}

const pageEntity = "institution";

const InstitutionPage: React.FC<InstitutionPage> = ({
  institutions,
  fetchInstitutions,
}) => {
  const { t } = useTranslation(pageEntity);
  const [idToDelete, setIdToDelete] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  
  useEffect(() => {
    fetchInstitutions();
  }, [fetchInstitutions]);

  const onSearchSubmit = (formData: SearchFormData): void => {
    fetchInstitutions(formData);
  };

  const triggerSubmit = (id: string) => {
    setIdToDelete(id);
    setShowSubmit(true);
  };

  const deleteEntityById = (id: string): void => {
    deleteEntity(pageEntity, id).then(() => fetchInstitutions());
  };

  const submitCallback = (): void => {
    setShowSubmit(false);
    deleteEntityById(idToDelete);
    setIdToDelete("");
  };

  const data = useMemo(
    () => (institutions.isFetching ? [] : institutions.data),
    [institutions]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("id"),
        accessor: "id",
        Cell: ({ row }: Cell): ReactNode => {
          const entityId = row.values.id;
          return (
            <Link to={`/${pageEntity}s/${entityId}`}>
              <LinkButton>{entityId.substring(0, 5)}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("name"),
        accessor: "name",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <Link to={`/${pageEntity}s/${row.values.id}`}>
              <LinkButton>{row.values.name}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("link"),
        accessor: "link",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <>
              {row.values.link && (
                <a href={row.values.link} target="_blank">
                  <FaExternalLinkAlt color="blue" />
                </a>
              )}
            </>
          );
        },
      },
      {
        Header: t("gps"),
        accessor: "gps",
      },
      {
        Header: t("entity:actions"),
        Cell: isAuthenticated && allowMailUsers.indexOf(user.email) > -1 ? (
          ({ row }: Cell): ReactNode => {
            return (
              <>
                <Link to={`/${pageEntity}s/${row.values.id}/edit`}>
                  <Button actionButton name="edit-button">
                    <FaPencilAlt color="#212529" />
                  </Button>
                </Link>
                <Button
                  actionButton
                  name="delete-button"
                  onClick={(): void => triggerSubmit(row.values.id)}
                >
                  <FaTrashAlt color="#212529" />
                </Button>
              </>
            );
          }
        ) : (
          <div />
        ),
      },
    ],
    [t, isAuthenticated]
  );

  return (
    <>
      <PageTitle>{t("pageTitle")}</PageTitle>
      <SearchForm
        attributes={["name"]}
        entity={pageEntity}
        onSearchSubmit={onSearchSubmit}
        onSearchReset={(): void => fetchInstitutions()}
      />
      <ButtonRow>
        <Link to={`/${pageEntity}s/${uuidv4()}/new`}>
          <Button>{t("entity:new")}</Button>
        </Link>
      </ButtonRow>
      <Table
        data={data}
        columns={columns}
        showLoading={institutions && institutions.isFetching}
      />
      <Submit
        title={t("delete.title")}
        text={t("delete.text", { id: idToDelete })}
        onSubmit={submitCallback}
        onCancel={(): void => setShowSubmit(false)}
        show={showSubmit}
      />
    </>
  );
};

const mapStateToProps = ({ institutions }: StateFromProps): StateToProps => ({
  institutions,
});

export default connect(mapStateToProps, {
  fetchInstitutions,
})(InstitutionPage);

interface StateFromProps {
  institutions: InstitutionsDataState;
}

interface StateToProps {
  institutions: InstitutionsDataState;
}
