import { combineReducers } from "redux";

import entities from "./entitiesReducer";
import entity from "./entityReducer";
import person from "./personReducer";
import universal from "./universalReducer";
import manuscript from "./manuscriptReducer";
import institution from "./institutionReducer";
import persons from "./personsReducer";
import universals from "./universalsReducer";
import manuscriptsUniversals from "./manuscriptsUniversalReducer";
import institutions from "./institutionsReducer";
import manuscripts from "./manuscriptsReducer";
import token from "./authTokenReducer";

const rootReducer = combineReducers({
  entities,
  entity,
  person,
  universal,
  manuscript,
  institution,
  persons,
  universals,
  manuscriptsUniversals,
  manuscripts,
  institutions,
  token,
});

export default rootReducer;
