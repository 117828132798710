import React, { useState, useEffect } from "react";
import { PageTitle } from "components";
import { getPosts } from "api/getPosts";
import styled from "styled-components";
import { listeners } from "cluster";

const Paragraph = styled.div`
  margin-top: 1.5rem;
`;

const List = styled.ul`
  list-style-type: square;
`

const ListItem = styled.li`
  margin-left: 2rem;
`

const Address = styled.div`
  margin-left: 3rem;
`


interface HomePage {}
const HomePage: React.FC<HomePage> = () => {
  // const [text, setText] = useState("");
  // useEffect(() => {
  //  getPosts("page", "Home").then((data) => setText(data[0].content));
  //}, []);

  return (
    <>
      <PageTitle>Home page</PageTitle>
      <Paragraph>The UniQ project is dedicated to the Prague struggle over universals ca. 1348–1500, 
        making information about the corresponding corpus of texts available in a digital environment. 
        It includes primarily information about texts written at the University of Prague and texts available 
        in Bohemia that are relevant to the dispute over universals, although their influence was broader.</Paragraph>
      <Paragraph>UniQ is based on the 1980 catalogue <i>Verzeichnis der Quellen zum Prager Universalienstreit 1348–1500 </i> 
        prepared by František Šmahel, which it updates and enriches with new data and metadata. 
        UniQ provides a modern tool for studying the history of the struggle over universals in 
        late medieval Central Europe.</Paragraph>
      <Paragraph>The application currently does not claim to be entirely up to date concerning individual texts and manuscripts.</Paragraph>
      <Paragraph>UniQ includes two main tools: 
      <List>
        <ListItem>The <a href="/manuscripts">Manuscripts</a> item consists of a catalogue of the manuscript corpus with extant texts on universals</ListItem>
        <ListItem>The <a href="/universals">Universals</a> item includes a catalogue of the corpus of texts on universals preserved in the manuscripts of our database</ListItem>
      </List>
      </Paragraph>
      <Paragraph>Users can learn more about the application’s functions in the <a href="/about">About</a> section; 
      the <a href="/sigla">Sigla</a> section contains information about the structure of UniQ’s sigla and the 
      method of using them when searching for and sorting texts in the database. </Paragraph>
      <Paragraph>To support the maintenance and further development of the database, we ask all users to mention the 
        use of UniQ in their research outcomes, using the permalinks available on the individual pages with 
        detailed information on texts and manuscripts.</Paragraph> 
      <Paragraph>UniQ has been developed at the Institute of Philosophy of the Czech Academy of Sciences within 
        the <a href="https://lindat.cz/" target="_blank">Lindat/CLARIAH-CZ</a> infrastructure 
        (LM2018101 and CZ.02.1.01/0.0/0.0/16_013/0001781; formerly LM2010013, LM2015071), which is fully supported 
        by the Czech Ministry of Education, Youth and Sports under programme LM of “Large Infrastructures”. 
        The team comprises Jan Škvrňák, Hana Cassi, and Ota Pavlíček.</Paragraph>
      <Paragraph>We welcome any feedback, suggestions for cooperation or enrichment of the application at: <br/>
      <Address>
        Ota Pavlíček<br/>
        Institute of Philosophy<br/>
        Czech Academy of Sciences<br/>
        Jilská 1<br/>
        110 00 Prague<br/>
        Czech Republic<br/>
        <a href="mailto:ota.pavlicek@flu.cas.cz">ota.pavlicek[at]flu.cas.cz</a>
      </Address>
      </Paragraph>
    </>
  );
};

export default HomePage;
