import { api } from "./api";
import {
  Person,
  SearchFormData,
  Institution,
  Universal,
  Manuscript,
  ManuscriptsUniversal,
  Authored, 
  Scribed
} from "common/types";
import store from "redux/store";

const getLink = (entityType: string, formData?: SearchFormData) => {
  if (formData) {
    switch (entityType) {
      case "person":
        return `/${entityType}s?name_like=${formData.name}`;
      case "institution":
        return `/${entityType}s?name_like=${formData.name}&gps_like=${formData.gps}`;
      case "universal":
        return `/${entityType}s?sigla_like=${formData.sigla2}&author_like=${formData.author}&title_like=${formData.title}&origin_like=${formData.origin}`;
      case "manuscript":
        return `/${entityType}s?shelfmark_like=${formData.shelfmark}&catalogue_like=${formData.catalogue}&origin_like=${formData.origin}`;
      case "manuscripts-universal":
        return `/${entityType}s?shelfmark_like=${formData.shelfmark}&sigla_like=${formData.sigla2}&author_like=${formData.author}&title_like=${formData.title}&origin_like=${formData.origin}`;
      default:
        return `/${entityType}s`;
    }
  } else {
      return `/${entityType}s`;
  }
};

export const getEntities = async (
  entityType: string,
  formData?: SearchFormData
): Promise<
  Person[] | Institution[] | Universal[] | Manuscript[] | ManuscriptsUniversal[]
> => {
  const authToken = store.getState().token;
  const config = {
    headers: { Authorization: `Bearer ${authToken}` },
  };
  try {
    const response = await api.get(getLink(entityType, formData), config);
    return await response.data;
  } catch (err) {
    throw err;
  }
};
