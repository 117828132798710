import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { Menu, Footer } from "components";
import logo from "assets/logos/f_zakladni_text_vedle_velky_cb.jpg";
import { setAuthToken } from "redux/actions/authActions";

const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-right: 5rem;
  padding-left: 2rem;
  overflow-y: auto;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;
const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3rem;
  min-width: 20%;
  overflow-y: auto;
`;
const Heading = styled.h1``;
const Logo = styled.img`
  width: 18rem;
  margin-top: 1rem;
  filter: grayscale(100%);
`;
const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  /* margin: 2rem 0; */

  /* border: 1px dashed white; */
`;

interface Page {
  children?: ReactNode;
  setAuthToken: (token: string) => void;
}

const Page: React.FC<Page> = ({ children, setAuthToken }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        setAuthToken(token);
      });
    }
  }, [isAuthenticated]);
  
  return (
    <Container>
      <SideBar>
        <Heading>UniQ</Heading>
        <Logo src={logo} />
        <Menu />
      </SideBar>
      <PageContainer>
        <Scroll>
          <Content>{children}</Content>
          <Footer />
        </Scroll>
      </PageContainer>
    </Container>
  );
};

export default connect(({}) => ({}), {
  setAuthToken,
})(Page);
