import {
  FetchPersonSuccessAction,
  FetchPersonRequestAction,
  FetchPersonFailureAction,
  FETCH_PERSON_SUCCESS,
  FETCH_PERSON_REQUEST,
  FETCH_PERSON_FAILURE,
} from "redux/types";
import { PersonDataState } from "common/types";

type ValidAction =
  | FetchPersonRequestAction
  | FetchPersonSuccessAction
  | FetchPersonFailureAction;

const initPerson = {
  id: "",
  name: "",
  note: "",
  wikidata: "",
  createdAt: "",
  updatedAt: "",
  rag: "",
  scribed: [], 
  authored: []
};
const person = (
  state = { data: initPerson },
  action: ValidAction
): PersonDataState => {
  switch (action.type) {
    case FETCH_PERSON_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_PERSON_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_PERSON_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default person;
