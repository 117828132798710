import React, { ReactNode, MouseEventHandler } from "react";
import styled from "styled-components";

import { globalRadius } from "common/constants";

interface StyledButton {
  actionButton?: boolean;
  light?: boolean;
  marginLeft?: boolean;
  marginRight?: boolean;
  marginTop?: boolean;
  disabled?: boolean;
}
const StyledButton = styled.button<StyledButton>`
  font-family: "LinLibertine";
  background-color: ${({ actionButton, light }) =>
    actionButton ? "rgba(0,0,0,0)" : light ? "white" : "black"};
  color: ${({ light }) => (light ? "black" : "white")};
  padding: ${({ actionButton }) => (actionButton ? 0 : "0.45rem 1rem")};
  border-radius: ${globalRadius};
  font-size: 1.7rem;
  border: ${({ actionButton }) => (actionButton ? "none" : "1px solid black")};
  margin-left: ${({ marginLeft }) => (marginLeft ? ".8rem" : 0)};
  margin-right: ${({ marginRight, actionButton }) =>
    marginRight ? ".8rem" : actionButton ? ".8rem" : 0};
  margin-top: ${({ marginTop }) => (marginTop ? "1.4rem" : 0)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  :focus {
    outline: none;
  }
`;

interface Button {
  onClick?: MouseEventHandler<HTMLElement>;
  children?: ReactNode;
  fullwidth?: boolean;
  type?: "button" | "submit" | "reset";
  actionButton?: boolean;
  light?: boolean;
  readonly?: boolean;
  marginLeft?: boolean;
  marginRight?: boolean;
  marginTop?: boolean;
  label?: string;
  name?: string;
}

export const Button: React.FC<Button> = ({
  onClick,
  children,
  fullwidth,
  type,
  readonly,
  actionButton,
  light,
  marginLeft,
  marginRight,
  marginTop,
  label,
  name,
}) => {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={readonly}
      actionButton={actionButton}
      light={light}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      name={name}
    >
      {label ? label : children}
    </StyledButton>
  );
};

Button.defaultProps = {
  onClick: (): void => {
    // do nothing
  },
  children: "",
  type: "button",
};
