import styled from "styled-components";

interface EntityDetailRow {
  noBorder?: boolean;
  marginTop?: boolean;
}
export const EntityDetailRow = styled.div<EntityDetailRow>`
  display: flex;
  flex-direction: row;
  margin-top: ${({ marginTop }) => (marginTop ? "1rem" : "0")};
  /* border-bottom: ${({ noBorder }) =>
    noBorder ? "none" : "1px solid lightgrey"}; */
  line-height: 1.6;
  padding: .4rem 0;
`;
