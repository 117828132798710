import React, { useState, useEffect } from "react";
import { PageTitle } from "components";
import { getPosts } from "api/getPosts";
import styled from "styled-components";

import grid from "assets/logos/grid.jpg";
import wikidata from "assets/logos/wikidata.png";
import gnd from "assets/logos/gnd.png";


interface AboutPage {}

const List = styled.ul`
  list-style-type: square;
  margin-top: 1.5rem;
`

const H2 = styled.h2`
  margin-top: 2rem;
`

const ListItem = styled.li`
  margin-left: 2rem;
`

const Paragraph = styled.div`
  margin-top: 1.5rem;
`

const ImageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
`

interface Image {
  width?: number;
}
const Image = styled.img<Image>`
  height: ${({ height }) => (height ? `${height}rem` : "14rem")};
  margin: 0 1rem;
`;
// filter: grayscale(100%);

const AboutPage: React.FC<AboutPage> = () => {
  //const [text, setText] = useState("");
  //useEffect(() => {
  //  getPosts("page", "About").then((data) => setText(data[0].content));
  //}, []);

  return (
    <>
      <PageTitle>About</PageTitle>
      <H2>Project description</H2>
      
      <Paragraph>The UniQ project is dedicated to the Prague struggle over universals ca. 1348–1500, making information about the corresponding corpus of texts available in a digital environment. It includes primarily texts written at the University of Prague and texts available in Bohemia that are relevant to the dispute over universals, although their influence was broader.</Paragraph>
      <Paragraph>The debates over universals at the University of Prague were a major driver of events both inside and outside the university, especially around 1390–1410 when reception of Wyclif’s philosophy was at its peak. It is thus not surprising that UniQ includes many texts connected with the reception of John Wyclif’s thought and treatises by Wyclif himself.</Paragraph>
      <Paragraph>UniQ is based on the 1980 catalogue <i>Verzeichnis der Quellen zum Prager Universalienstreit 1348–1500</i> prepared by František Šmahel, which it updates and enriches with new data and metadata that were unavailable at that time. UniQ provides a modern tool for studying the history of the struggle over universals in late medieval Central Europe. The main inspiration for the project is the database of the <a href="https://ptolemaeus.badw.de/start" target="_blank">Ptolemaeus Arabus et Latinus</a> project.</Paragraph>

      <H2>UniQ’s two main tools</H2>

      <List><ListItem>The <a href="/manuscripts"><b>Manuscripts</b></a> tool includes a catalogue of the manuscript corpus with extant texts on universals</ListItem></List>
      <Paragraph>The basic listing contains all manuscript shelf-marks in the database, individual treatises, and basic data about these works. If there is a manuscript facsimile, we refer to it at the end of each line of the listing. The basic listing allows item sorting using the search function. Additional information about the manuscript can be found in the detailed listing displayed after clicking on the manuscript shelf-mark, including a link to the (digitised) catalogue and a list of all the works in the database contained in the manuscript. More can be learned about individual data and metadata through further clicks. We use Linked Open Data for some metadata and refer to specialised databases (Wikidata, Gemeinsame Normdatei, Global Research Identifier Database).</Paragraph>
      <ImageBox>
        <a href="https://www.wikidata.org/wiki/Wikidata:Main_Page" target="_blank"><Image src={wikidata} height={12}></Image></a>
        <a href="https://www.grid.ac/" target="_blank"><Image src={gnd} height={12}></Image></a>
        <a href="https://www.dnb.de/DE/Professionell/Standardisierung/GND/gnd_node.html" target="_blank"><Image src={grid} height={12}></Image></a>
      </ImageBox>
      <List><ListItem>The <a href="/universals"><b>Universals</b></a> tool includes a catalogue of the corpus of texts on universals preserved in the manuscripts of our database</ListItem></List>
      <Paragraph>The basic listing contains titles of all writings in the database, together with the authors’ names, dates of origin, and, if an edition exists, a bibliographic reference. If legal regulations permit, a link to the digitised edition is also present. Clicking on the title of the work will bring the user to detailed information about it, including the incipits, the manuscripts in which the text has been preserved, and the literature. More can be learned about individual data and metadata through further clicks. We use Linked Open Data for some metadata and refer to specialised databases (Wikidata, Gemeinsame Normdatei, Global Research Identifier Database).</Paragraph>
  
      <Paragraph>Both tools (Manuscripts, Universals) enable data sorting through a search in the basic listing. This makes it possible to group texts for more effective research, and to grasp information that would be more difficult to obtain if working with a printed catalogue. This function is further strengthened by UniQ’s system of <i>sigla</i> which allows advanced search and listing of the writings according to their type. More details on the <i>sigla</i> may be found elsewhere on the <a href="/sigla">UniQ website</a>.</Paragraph>
      <Paragraph>We plan to implement a map function in the future, which will allow visualising the locations where works in our database are stored. It will also be possible to export our metadata in XML TEI 5 format, and we intend to join the Open Archives Initiative.</Paragraph>
      <Paragraph>UniQ is currently based mainly on the data gathered by Šmahel and does not yet include a complete update of the data based on research of the current literature, which has expanded greatly since the publication of Šmahel’s <i>Verzeichnis</i>.</Paragraph>
    </>
  );
};

export default AboutPage;
