import React, { useEffect, useMemo, ReactNode, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Cell, Row } from "react-table";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";

import {
  SearchForm,
  Table,
  PageTitle,
  LinkButton,
  Button,
  ButtonRow,
  Submit,
} from "components";
import {
  SearchFormData,
  UniversalsDataState,
  PersonsDataState,
  PersonBinding
} from "common/types";
import { allowMailUsers } from "common/users";
import { fetchUniversals, fetchPersons } from "redux/actions/entitiesActions";
import { deleteEntity } from "api/deleteEntity";
import { useAuth0 } from "@auth0/auth0-react";
import { findPersonById } from "utils";

interface UniversalPage {
  universals: UniversalsDataState;
  fetchUniversals: (formData?: SearchFormData) => void;
  persons: PersonsDataState;
  fetchPersons: (formData?: SearchFormData) => void;
}

const pageEntity = "universal";

const UniversalPage: React.FC<UniversalPage> = ({
  universals,
  fetchUniversals,
  persons,
  fetchPersons,
}) => {
  const { t } = useTranslation(pageEntity);
  const [idToDelete, setIdToDelete] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  
  useEffect(() => {
    fetchUniversals();
  }, [fetchUniversals]);

  
  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);
  
  const data = useMemo(() => (universals.isFetching ? [] : universals.data), [
    universals,
  ]);

  const sortByName = (a: Row, b: Row) => {
    return a.values.authors_id[0].name.localeCompare(b.values.authors_id[0].name)
  };

  const columns = useMemo(
    () => [
      {
        Header: t("id"),
        accessor: "id",
        Cell: ({ row }: Cell): ReactNode => {
          const entityId = row.values.id;
          return (
            <Link to={`/${pageEntity}s/${entityId}`}>
              <LinkButton>{entityId.substring(0, 5)}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("sigla"),
        accessor: "sigla2",
      },
      {
        Header: t("authors"),
        accessor: "authors_id",
        sortType: sortByName,
        Cell: ({ row }: Cell): ReactNode => {
          const authorsId : PersonBinding[] = row.values.authors_id;
          return (
            <>
              {authorsId &&
                authorsId.map((author: PersonBinding, key: number) => {
                  // const author = findPersonById(authorId, persons.data);
                  return (
                    <p key={key}>
                      <Link to={`/persons/${author.id}`}>
                        <LinkButton>
                          {author.name}
                        </LinkButton>
                      </Link>
                      
                    </p>
                  );
                })}
            </>
          );
        }, 
      },
      {
        Header: t("title"),
        accessor: "title",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <Link to={`/${pageEntity}s/${row.values.id}`}>
              <LinkButton>{row.values.title}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("origin"),
        accessor: "origin",
      },
      // {
      //   Header: t("facsimile"),
      //   accessor: "facsimile",
      // },
      {
        Header: t("edition_link"),
        accessor: "edition_link",
      },
      {
        Header: t("edition"),
        accessor: "edition",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <>
              {row.values.edition_link !== undefined && row.values.edition_link !== "" ? (
                <a href={row.values.edition_link} target="_blank">
                  {row.values.edition}
                </a>
              ) : (
                <p>{row.values.edition}</p>
              )}
            </>
          );
        },
      },
      {
        Header: t("entity:actions"),
        Cell: isAuthenticated && allowMailUsers.indexOf(user.email) > -1 ? (
          ({ row }: Cell): ReactNode => {
            return (
              <>
                <Link to={`/${pageEntity}s/${row.values.id}/edit`}>
                  <Button actionButton name="edit-button">
                    <FaPencilAlt color="#212529" />
                  </Button>
                </Link>
                <Button
                  actionButton
                  name="delete-button"
                  onClick={(): void => triggerSubmit(row.values.id)}
                >
                  <FaTrashAlt color="#212529" />
                </Button>
              </>
            );
          }
        ) : (
          <div />
        ),
      },
    ],
    [t, isAuthenticated]
  );

  const onSearchSubmit = (formData: SearchFormData): void => {
    fetchUniversals(formData)
  };

  const triggerSubmit = (id: string) => {
    setIdToDelete(id);
    setShowSubmit(true);
  };

  const deleteEntityById = (id: string): void => {
    deleteEntity(pageEntity, id).then(() => fetchUniversals());
  };

  const submitCallback = (): void => {
    setShowSubmit(false);
    deleteEntityById(idToDelete);
    setIdToDelete("");
  };

  return (
    <>
      <PageTitle>{t("pageTitle")}</PageTitle>
      <SearchForm
        attributes={["sigla2", "author", "title", "origin"]}
        entity={pageEntity}
        onSearchSubmit={onSearchSubmit}
        onSearchReset={(): void => fetchUniversals()}
        readonly={universals.isFetching}
      />
      {
        isAuthenticated && allowMailUsers.indexOf(user.email) > -1 ?
        <ButtonRow>
        <Link to={`/${pageEntity}s/${uuidv4()}/new`}>
          <Button>{t("entity:new")}</Button>
        </Link>
        </ButtonRow> : 
        <ButtonRow></ButtonRow>
      }
      
      <Table
        data={data}
        columns={columns}
        showLoading={universals && universals.isFetching}
      />
      <Submit
        title={t("delete.title")}
        text={t("delete.text", { id: idToDelete })}
        onSubmit={submitCallback}
        onCancel={(): void => setShowSubmit(false)}
        show={showSubmit}
      />
    </>
  );
};

const mapStateToProps = ({
  universals,
  persons,
}: StateFromProps): StateToProps => ({
  universals,
  persons,
});

export default connect(mapStateToProps, {
  fetchUniversals,
  fetchPersons,
})(UniversalPage);

interface StateFromProps {
  universals: UniversalsDataState;
  persons: PersonsDataState;
}

interface StateToProps {
  universals: UniversalsDataState;
  persons: PersonsDataState;
}
