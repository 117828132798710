import {
  Person,
  Institution,
  Universal,
  Manuscript,
  ManuscriptsUniversal,
  Authored, 
  Scribed
} from "common/types";

export const FETCH_ENTITIES = "FETCH_ENTITIES";
export const FETCH_ENTITIES_REQUEST = "FETCH_ENTITIES/REQUEST";
export const FETCH_ENTITIES_SUCCESS = "FETCH_ENTITIES/SUCCESS";
export const FETCH_ENTITIES_FAILURE = "FETCH_ENTITIES/FAILURE";

export const FETCH_PERSONS_REQUEST = "FETCH_PERSONS/REQUEST";
export const FETCH_PERSONS_SUCCESS = "FETCH_PERSONS/SUCCESS";
export const FETCH_PERSONS_FAILURE = "FETCH_PERSONS/FAILURE";

export const FETCH_UNIVERSALS_REQUEST = "FETCH_UNIVERSALS/REQUEST";
export const FETCH_UNIVERSALS_SUCCESS = "FETCH_UNIVERSALS/SUCCESS";
export const FETCH_UNIVERSALS_FAILURE = "FETCH_UNIVERSALS/FAILURE";

export const FETCH_MANUSCRIPTS_UNIVERSALS_REQUEST =
  "FETCH_MANUSCRIPTS_UNIVERSALS/REQUEST";
export const FETCH_MANUSCRIPTS_UNIVERSALS_SUCCESS =
  "FETCH_MANUSCRIPTS_UNIVERSALS/SUCCESS";
export const FETCH_MANUSCRIPTS_UNIVERSALS_FAILURE =
  "FETCH_MANUSCRIPTS_UNIVERSALS/FAILURE";

export const FETCH_MANUSCRIPTS_REQUEST = "FETCH_MANUSCRIPTS/REQUEST";
export const FETCH_MANUSCRIPTS_SUCCESS = "FETCH_MANUSCRIPTS/SUCCESS";
export const FETCH_MANUSCRIPTS_FAILURE = "FETCH_MANUSCRIPTS/FAILURE";

export const FETCH_INSTITUTIONS_REQUEST = "FETCH_INSTITUTIONS/REQUEST";
export const FETCH_INSTITUTIONS_SUCCESS = "FETCH_INSTITUTIONS/SUCCESS";
export const FETCH_INSTITUTIONS_FAILURE = "FETCH_INSTITUTIONS/FAILURE";

export const FETCH_ENTITY_REQUEST = "FETCH_ENTITY/REQUEST";
export const FETCH_ENTITY_SUCCESS = "FETCH_ENTITY/SUCCESS";
export const FETCH_ENTITY_FAILURE = "FETCH_ENTITY/FAILURE";

export const FETCH_PERSON_REQUEST = "FETCH_PERSON/REQUEST";
export const FETCH_PERSON_SUCCESS = "FETCH_PERSON/SUCCESS";
export const FETCH_PERSON_FAILURE = "FETCH_PERSON/FAILURE";

export const FETCH_INSTITUTION_REQUEST = "FETCH_INSTITUTION/REQUEST";
export const FETCH_INSTITUTION_SUCCESS = "FETCH_INSTITUTION/SUCCESS";
export const FETCH_INSTITUTION_FAILURE = "FETCH_INSTITUTION/FAILURE";

export const FETCH_UNIVERSAL_REQUEST = "FETCH_UNIVERSAL/REQUEST";
export const FETCH_UNIVERSAL_SUCCESS = "FETCH_UNIVERSAL/SUCCESS";
export const FETCH_UNIVERSAL_FAILURE = "FETCH_UNIVERSAL/FAILURE";

export const FETCH_MANUSCRIPT_REQUEST = "FETCH_MANUSCRIPT/REQUEST";
export const FETCH_MANUSCRIPT_SUCCESS = "FETCH_MANUSCRIPT/SUCCESS";
export const FETCH_MANUSCRIPT_FAILURE = "FETCH_MANUSCRIPT/FAILURE";

export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";

export type FetchEntitiesRequestAction = {
  type: typeof FETCH_ENTITIES_REQUEST;
  payload: {};
};
export type FetchEntitiesSuccessAction = {
  type: typeof FETCH_ENTITIES_SUCCESS;
  payload:
    | Person[]
    | Institution[]
    | Universal[]
    | Manuscript[]
    | ManuscriptsUniversal[];
};
export type FetchEntitiesFailureAction = {
  type: typeof FETCH_ENTITIES_FAILURE;
  payload: Error;
  error: boolean;
};
export type FetchPersonsRequestAction = {
  type: typeof FETCH_PERSONS_REQUEST;
  payload: {};
};
export type FetchPersonsSuccessAction = {
  type: typeof FETCH_PERSONS_SUCCESS;
  payload: Person[];
};
export type FetchPersonsFailureAction = {
  type: typeof FETCH_PERSONS_FAILURE;
  payload: Error;
  error: boolean;
};

export type FetchUniversalsRequestAction = {
  type: typeof FETCH_UNIVERSALS_REQUEST;
  payload: {};
};
export type FetchUniversalsSuccessAction = {
  type: typeof FETCH_UNIVERSALS_SUCCESS;
  payload: Universal[];
};
export type FetchUniversalsFailureAction = {
  type: typeof FETCH_UNIVERSALS_FAILURE;
  payload: Error;
  error: boolean;
};
export type FetchManuscriptsUniversalsRequestAction = {
  type: typeof FETCH_MANUSCRIPTS_UNIVERSALS_REQUEST;
  payload: {};
};
export type FetchManuscriptsUniversalsSuccessAction = {
  type: typeof FETCH_MANUSCRIPTS_UNIVERSALS_SUCCESS;
  payload: ManuscriptsUniversal[];
};
export type FetchManuscriptsUniversalsFailureAction = {
  type: typeof FETCH_MANUSCRIPTS_UNIVERSALS_FAILURE;
  payload: Error;
  error: boolean;
};
export type FetchManuscriptsRequestAction = {
  type: typeof FETCH_MANUSCRIPTS_REQUEST;
  payload: {};
};
export type FetchManuscriptsSuccessAction = {
  type: typeof FETCH_MANUSCRIPTS_SUCCESS;
  payload: Manuscript[];
};
export type FetchManuscriptsFailureAction = {
  type: typeof FETCH_MANUSCRIPTS_FAILURE;
  payload: Error;
  error: boolean;
};
export type FetchInstitutionsRequestAction = {
  type: typeof FETCH_INSTITUTIONS_REQUEST;
  payload: {};
};
export type FetchInstitutionsSuccessAction = {
  type: typeof FETCH_INSTITUTIONS_SUCCESS;
  payload: Institution[];
};
export type FetchInstitutionsFailureAction = {
  type: typeof FETCH_INSTITUTIONS_FAILURE;
  payload: Error;
  error: boolean;
};

export type FetchEntityRequestAction = {
  type: typeof FETCH_ENTITY_REQUEST;
  payload: {};
};
export type FetchEntitySuccessAction = {
  type: typeof FETCH_ENTITY_SUCCESS;
  payload: Person | Institution | Universal | Manuscript;
};
export type FetchEntityFailureAction = {
  type: typeof FETCH_ENTITY_FAILURE;
  payload: Error;
  error: boolean;
};

export type FetchPersonRequestAction = {
  type: typeof FETCH_PERSON_REQUEST;
  payload: {};
};
export type FetchPersonSuccessAction = {
  type: typeof FETCH_PERSON_SUCCESS;
  payload: Person;
};
export type FetchPersonFailureAction = {
  type: typeof FETCH_PERSON_FAILURE;
  payload: Error;
  error: boolean;
};

export type FetchInstitutionRequestAction = {
  type: typeof FETCH_INSTITUTION_REQUEST;
  payload: {};
};
export type FetchInstitutionSuccessAction = {
  type: typeof FETCH_INSTITUTION_SUCCESS;
  payload: Institution;
};
export type FetchInstitutionFailureAction = {
  type: typeof FETCH_INSTITUTION_FAILURE;
  payload: Error;
  error: boolean;
};

export type FetchUniversalRequestAction = {
  type: typeof FETCH_UNIVERSAL_REQUEST;
  payload: {};
};
export type FetchUniversalSuccessAction = {
  type: typeof FETCH_UNIVERSAL_SUCCESS;
  payload: Universal;
};
export type FetchUniversalFailureAction = {
  type: typeof FETCH_UNIVERSAL_FAILURE;
  payload: Error;
  error: boolean;
};

export type FetchManuscriptRequestAction = {
  type: typeof FETCH_MANUSCRIPT_REQUEST;
  payload: {};
};
export type FetchManuscriptSuccessAction = {
  type: typeof FETCH_MANUSCRIPT_SUCCESS;
  payload: Manuscript;
};
export type FetchManuscriptFailureAction = {
  type: typeof FETCH_MANUSCRIPT_FAILURE;
  payload: Error;
  error: boolean;
};

export interface AuthTokenAction {
  type: typeof SET_AUTH_TOKEN;
  token: string;
}
