import {
  FetchUniversalsRequestAction,
  FetchUniversalsSuccessAction,
  FetchUniversalsFailureAction,
  FETCH_UNIVERSALS_REQUEST,
  FETCH_UNIVERSALS_SUCCESS,
  FETCH_UNIVERSALS_FAILURE,
} from "redux/types";
import { UniversalsDataState } from "common/types";

type ValidAction =
  | FetchUniversalsRequestAction
  | FetchUniversalsSuccessAction
  | FetchUniversalsFailureAction;

const universals = (
  state = { data: [] },
  action: ValidAction
): UniversalsDataState => {
  switch (action.type) {
    case FETCH_UNIVERSALS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_UNIVERSALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_UNIVERSALS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default universals;
