import { api } from "./api";
import { Person, Institution, Universal, Manuscript } from "common/types";
import store from "redux/store";

export const deleteEntity = async (
  entityType: string,
  id: string
): Promise<Person | Institution | Universal | Manuscript> => {
  const authToken = store.getState().token;
  const config = {
    headers: { Authorization: `Bearer ${authToken}` },
  };
  try {
    const response = await api.delete(`/${entityType}s/${id}`, config);
    return await response.data;
  } catch (err) {
    throw err;
  }
};
