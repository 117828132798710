import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "localization/en.json";

// Resources are namespaces - it's possible to specify namespace for particular component, to shorten localization keys
const resources = {
  en: {
    translation: en,
    page: en.page,
    entity: en.entity,
    person: en.person,
    institution: en.institution,
    universal: en.universal,
    manuscript: en.manuscript,
    searchForm: en.searchForm,
    table: en.table,
    submit: en.submit,
    manuscriptsUniversal: en.manuscriptsUniversal,
    manuscriptUniversalBinding: en.manuscriptUniversalBinding,
    map: en.map,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
