import {
  FetchEntitiesRequestAction,
  FetchEntitiesSuccessAction,
  FetchEntitiesFailureAction,
  FETCH_ENTITIES_REQUEST,
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITIES_FAILURE,
} from "redux/types";
import { EntitiesDataState } from "common/types";

type ValidAction =
  | FetchEntitiesRequestAction
  | FetchEntitiesSuccessAction
  | FetchEntitiesFailureAction;

const entities = (
  state = { data: [] },
  action: ValidAction
): EntitiesDataState => {
  switch (action.type) {
    case FETCH_ENTITIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_ENTITIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_ENTITIES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default entities;
