import { api } from "./api";
import {
  Person,
  Institution,
  Universal,
  Manuscript,
  ManuscriptsUniversal,
} from "common/types";
import store from "redux/store";

export const addEntity = async (
  entityType: string,
  entity: Person | Institution | Universal | Manuscript | ManuscriptsUniversal
): Promise<
  Person | Institution | Universal | Manuscript | ManuscriptsUniversal
> => {
  // const authToken = store.getState().token;
  const config = {
    // headers: { Authorization: `Bearer ${authToken}` },
  };
  try {
    console.log(entity);
    const response = await api.post(`/${entityType}s`, entity, config);
    return await response.data;
  } catch (err) {
    throw err;
  }
};
