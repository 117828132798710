import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import DotLoader from "react-spinners/DotLoader";

import { PersonDataState, Person, UseParamsId, Authored, Scribed } from "common/types";
import { fetchPerson } from "redux/actions/entityActions";
import {
  EntityDetailHeading as Heading,
  EntityDetailBlockHeading as BlockHeading,
  EntityDetailRow as Row,
  EntityDetailColumn as Column,
  Input,
  Button,
  Toast,
} from "components";
import { updateEntity } from "api/updateEntity";
import { addEntity } from "api/addEntity";
import { toast } from "react-toastify";
import { getDateTimeFromUtf } from "utils";
import styled from "styled-components";

const EntityDetailSubheading = styled.h2`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const StyledALink = styled.a`
  /* text-decoration: none; */
  :visited {
    color: blue;
  }
`;

export const WorkColumn = styled.div`
  display: flex;
  width: 60rem;
  /* align-items: center; */
  font-size: 1.6rem;
  /* line-height: 1.6; */
`;


interface PersonDetail {
  person: PersonDataState;
  fetchPerson: (id: string) => void;
  isNew?: boolean;
  isEdit?: boolean;
}
type FormData = {
  id: string;
  name: string;
  note: string | null;
};

const pageEntity = "person";
const PersonDetail: React.FC<PersonDetail> = ({
  person,
  fetchPerson,
  isNew,
  isEdit,
}) => {
  const { t } = useTranslation([pageEntity, "entity"]);
  const history = useHistory();
  const { id } = useParams<UseParamsId>();
  const { name, note, createdAt, updatedAt, authored, scribed, wikidata, rag } = person.data;
  const { register, handleSubmit, setValue } = useForm<FormData>();

  useEffect(() => {
    if (!isNew) {
      fetchPerson(id);
    }
  }, [fetchPerson, id, isNew]);

  useEffect(() => {
    if (isNew) {
      setValue("id", id);
      setValue("name", "");
      setValue("note", "");
      setValue("wikidata", "");
      setValue("rag", "");
    } else if (!person.isFetching) {
      setValue("id", id);
      setValue("name", name);
      setValue("note", note);
      setValue("wikidata", wikidata);
      setValue("rag", rag);
    }
  }, [setValue, isNew, id, name, note, person.isFetching]);

  const onSubmit = ({ name, note }: FormData): void => {
    const personObject = {
      id: id,
      name: name,
      note: note,
    };
    if (isNew) {
      addEntity(pageEntity, personObject as Person).then(() => {
        history.push(`/${pageEntity}s/${id}/edit`);
        createNotify();
      });
    } else {
      updateEntity(pageEntity, personObject as Person).then(() => {
        console.log("update notification");
        saveNotify();
      });
    }
  };

  const createNotify = () => toast.dark(t("createSuccess"));
  const saveNotify = () => toast.dark(t("saveSuccess"));

  return (
    <>
      {person && !person.isFetching ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <BlockHeading>{t("detailTitle")}</BlockHeading>
          <Row>
            <Column attr>
              <b>{t("id")}</b>
            </Column>
            <Column>{id}</Column>
          </Row>
          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("name")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="name"
                  register={register}
                  required
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("name")}</b>
              </Column>
              <Column>{name}</Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("note")}</b>
              </Column>
              <Column>
                <Input underline type="text" name="note" register={register} />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("note")}</b>
              </Column>
              <Column>{note}</Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("wikidata")}</b>
              </Column>
              <Column>
                <Input underline type="text" name="wikidata" register={register} />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("wikidata")}</b>
              </Column>
              <Column>
                <StyledALink href={"https://www.wikidata.org/wiki/" + wikidata} target="_blank">
                  {wikidata}
                </StyledALink>
              </Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("rag")}</b>
              </Column>
              <Column>
                <Input underline type="text" name="rag" register={register} />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("rag")}</b>
              </Column>
              <Column>
                <StyledALink href={rag} target="_blank">
                  {rag}
                </StyledALink>
              </Column>
            </Row>
          )}

          {isEdit && (
            <>
              <Row>
                <Column attr>
                  <b>{t("entity:created")}</b>
                </Column>
                <Column>{getDateTimeFromUtf(createdAt)}</Column>
              </Row>
              <Row>
                <Column attr>
                  <b>{t("entity:updated")}</b>
                </Column>
                <Column>{getDateTimeFromUtf(updatedAt)}</Column>
              </Row>
            </>
          )}

          <Row>
            <Column attr>
              <b>{t("entity:permalink")}</b>
            </Column>
            <Column>{`${window.location.origin}/${pageEntity}s/${id}`}</Column>
          </Row>

          {person && authored ? (
            <div>
              <EntityDetailSubheading>Authored works:</EntityDetailSubheading>
              <Row>
                <WorkColumn><b>Universal</b></WorkColumn>
                <WorkColumn><b>Sigla</b></WorkColumn>
              </Row>
            </div>
          ) : (<></>)}
          {person && !person.isFetching && authored ? (
            authored.map((authored_work: Authored, key) => 
            <Row key={key}>
              <WorkColumn>
                <Link to={`/universals/` + authored_work.universal_id}>{authored_work.title}</Link>
              </WorkColumn>
              <WorkColumn>{authored_work.sigla2}</WorkColumn>
            </Row>)
          ) : (
            <></>
          )}

          {person && scribed ? (
            <div>
            <EntityDetailSubheading>Scribe of:</EntityDetailSubheading>
            <Row>
              <WorkColumn><b>Universal</b></WorkColumn>
              <WorkColumn><b>Manuscript shelfmark</b></WorkColumn>
            </Row>
            </div>
          ) : (<></>)}
          {person && !person.isFetching && scribed ? (
            scribed.map((scribed_work: Scribed, key) => 
            <Row key={key}>
              <WorkColumn>
                <Link to={`/universals/` + scribed_work.universal_id}>{scribed_work.title}</Link>
              </WorkColumn>
              <WorkColumn>
                <Link to={`/manuscripts/` + scribed_work.manuscript_id}>{scribed_work.shelfmark}</Link>
              </WorkColumn>
            </Row>
            )
          ) : (
            <></>
          )}

          {isNew || isEdit ? (
            <Row noBorder marginTop>
              <Column attr></Column>
              <Column>
                <Link to={`/${pageEntity}s`}>
                  <Button light marginRight>
                    {t("entity:cancel")}
                  </Button>
                </Link>
                <Button type="submit">{t("entity:save")}</Button>
              </Column>
            </Row>
          ) : (
            <Row noBorder marginTop>
              <Column attr>
                <Link to={`/${pageEntity}s`}>
                  <Button>{t("entity:back")}</Button>
                </Link>
              </Column>
              <Column></Column>
            </Row>
          )}
        </form>
      ) : (
        <DotLoader
          size={70}
          color="#000"
          css={`
            margin: 20rem auto;
          `}
        />
      )}
      <Toast />
    </>
  );
};

const mapStateToProps = ({ person }: StateFromProps): StateToProps => ({
  person,
});

export default connect(mapStateToProps, {
  fetchPerson,
})(PersonDetail);

interface StateFromProps {
  person: PersonDataState;
}

interface StateToProps {
  person: PersonDataState;
}