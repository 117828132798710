import React, { useEffect, useMemo, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { Cell } from "react-table";
import { Link } from "react-router-dom";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";

import {
  SearchForm,
  Table,
  PageTitle,
  LinkButton,
  ButtonRow,
  Button,
  Submit,
} from "components";
import { allowMailUsers } from "common/users";
import { SearchFormData, ManuscriptsDataState } from "common/types";
import { fetchManuscripts } from "redux/actions/entitiesActions";
import { deleteEntity } from "api/deleteEntity";
import { useAuth0 } from "@auth0/auth0-react";

interface ManuscriptPage {
  manuscripts: ManuscriptsDataState;
  fetchManuscripts: (formData?: SearchFormData) => void;
}

const pageEntity = "manuscript";
// Menu: Manuscript admin
const ManuscriptPage: React.FC<ManuscriptPage> = ({
  manuscripts,
  fetchManuscripts,
}) => {
  const { t } = useTranslation(pageEntity);
  const [idToDelete, setIdToDelete] = useState("");
  const [showSubmit, setShowSubmit] = useState(false);
  const { user, isAuthenticated } = useAuth0();
  
  useEffect(() => {
    fetchManuscripts();
  }, [fetchManuscripts]);

  const onSearchSubmit = (formData: SearchFormData): void => {
    fetchManuscripts(formData);
  };

  const triggerSubmit = (id: string) => {
    setIdToDelete(id);
    setShowSubmit(true);
  };

  const deleteEntityById = (id: string): void => {
    deleteEntity(pageEntity, id).then(() => fetchManuscripts());
  };

  const submitCallback = (): void => {
    setShowSubmit(false);
    deleteEntityById(idToDelete);
    setIdToDelete("");
  };

  const data = useMemo(() => (manuscripts.isFetching ? [] : manuscripts.data), [
    manuscripts,
  ]);

  console.log(data);

  const columns = useMemo(
    () => [
      {
        Header: t("id"),
        accessor: "id",
        Cell: ({ row }: Cell): ReactNode => {
          const entityId = row.values.id;
          return (
            <Link to={`/${pageEntity}s/${entityId}`}>
              <LinkButton>{entityId.substring(0, 5)}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("shelfmark"),
        accessor: "shelfmark",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <Link to={`/${pageEntity}s/${row.values.id}`}>
              <LinkButton>{row.values.shelfmark}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("catalogue"),
        accessor: "catalogue",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <>
              {row.values.digital_copy ? (
                <a href={row.values.digital_copy} target="_blank">
                  <LinkButton>{row.values.catalogue}</LinkButton>
                </a>
              ) : (
                <p>{row.values.catalogue}</p>
              )}
            </>
          );
        },
      },
      {
        Header: t("digital_copy"),
        accessor: "digital_copy",
      },
      {
        Header: t("origin"),
        accessor: "origin",
      },
      {
        Header: t("entity:actions"),
        Cell:
          isAuthenticated && allowMailUsers.indexOf(user.email) > -1 ? (
            ({ row }: Cell): ReactNode => {
              return (
                <>
                  <Link to={`/${pageEntity}s/${row.values.id}/edit`}>
                    <Button actionButton name="edit-button">
                      <FaPencilAlt color="#212529" />
                    </Button>
                  </Link>
                  <Button
                    actionButton
                    name="delete-button"
                    onClick={(): void => triggerSubmit(row.values.id)}
                  >
                    <FaTrashAlt color="#212529" />
                  </Button>
                </>
              );
            }
          ) : (
            <div />
          ),
      },
    ],
    [t, isAuthenticated]
  );

  return (
    <>
      <PageTitle>{t("pageTitle")}</PageTitle>
      <SearchForm
        attributes={["shelfmark", "catalogue", "origin"]}
        entity={pageEntity}
        onSearchSubmit={onSearchSubmit}
        onSearchReset={(): void => fetchManuscripts()}
      />
      <ButtonRow>
        <Link to={`/${pageEntity}s/${uuidv4()}/new`}>
          <Button>{t("entity:new")}</Button>
        </Link>
      </ButtonRow>
      <Table
        data={data}
        columns={columns}
        showLoading={manuscripts && manuscripts.isFetching}
      />
      <Submit
        title={t("delete.title")}
        text={t("delete.text", { id: idToDelete })}
        onSubmit={submitCallback}
        onCancel={(): void => setShowSubmit(false)}
        show={showSubmit}
      />
    </>
  );
};

const mapStateToProps = ({ manuscripts }: StateFromProps): StateToProps => ({
  manuscripts,
});

export default connect(mapStateToProps, {
  fetchManuscripts,
})(ManuscriptPage);

interface StateFromProps {
  manuscripts: ManuscriptsDataState;
}

interface StateToProps {
  manuscripts: ManuscriptsDataState;
}
