import React, { ReactNode } from "react";
import Select, {
  OptionsType,
  OptionTypeBase,
  GroupedOptionsType,
  ValueType,
  Theme,
} from "react-select";
import styled from "styled-components";

const StyledDropDown = styled(Select)`
  width: 100%;
`;

interface DropDown {
  options?: OptionsType<OptionTypeBase> | GroupedOptionsType<OptionTypeBase>;
  value?: ValueType<OptionTypeBase>;
  onChange: (selectedOption: ValueType<OptionTypeBase>) => void;
  isMulti?: boolean;
  ref?: React.RefObject<ReactNode>;
}
export const DropDown: React.FC<DropDown> = ({
  options,
  value,
  onChange,
  isMulti,
}) => {
  // const [selectedOption, setSelectedOption] = useState<
  //   ValueType<OptionTypeBase>
  // >(null);
  // const handleChange = (selectedOption: ValueType<OptionTypeBase>) => {
  //   setSelectedOption(selectedOption);
  //   console.log(`Option selected:`, selectedOption);
  // };

  return (
    <>
      <StyledDropDown
        isMulti={isMulti}
        value={value}
        onChange={onChange}
        options={options}
        theme={(theme: Theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            text: "white",
            primary25: "grey",
            primary50: "grey",
            primary: "black",
          },
        })}
      />
    </>
  );
};
