import {
  FetchPersonsRequestAction,
  FetchPersonsSuccessAction,
  FetchPersonsFailureAction,
  FETCH_PERSONS_REQUEST,
  FETCH_PERSONS_SUCCESS,
  FETCH_PERSONS_FAILURE,
} from "redux/types";
import { PersonsDataState } from "common/types";

type ValidAction =
  | FetchPersonsRequestAction
  | FetchPersonsSuccessAction
  | FetchPersonsFailureAction;

const persons = (
  state = { data: [] },
  action: ValidAction
): PersonsDataState => {
  switch (action.type) {
    case FETCH_PERSONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_PERSONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_PERSONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default persons;
