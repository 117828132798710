import {
  InstitutionsDataState,
  ManuscriptsDataState,
  ManuscriptsUniversalsDataState,
  PersonsDataState,
  SearchFormData,
  Universal,
  UniversalDataState,
} from "common/types";
import { PageTitle } from "components";
import L from "leaflet";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Arc, Group, Layer, Rect, Stage, Text } from "react-konva";
import { connect } from "react-redux";
import {
  fetchInstitutions,
  fetchManuscripts,
  fetchManuscriptsUniversals,
  fetchPersons,
  fetchUniversals,
} from "redux/actions/entitiesActions";
import styled from "styled-components";
import "./../../../node_modules/leaflet/dist/leaflet.css";

const dateDict = require("./date-dict.json");

/**
 * Filters
 */
interface IFilter {
  spatial: string | false;
  author: string | false;
  sigla: string | false;
  date: string | false;
}

const defaultFilter: IFilter = {
  spatial: false,
  author: false,
  sigla: false,
  date: false,
};

interface MapPage {
  manuscripts: ManuscriptsDataState;
  fetchManuscripts: (formData?: SearchFormData) => void;
  manuscriptsUniversals: ManuscriptsUniversalsDataState;
  fetchManuscriptsUniversals: (formData?: SearchFormData) => void;
  persons: PersonsDataState;
  fetchPersons: (formData?: SearchFormData) => void;
  institutions: InstitutionsDataState;
  fetchInstitutions: (formData?: SearchFormData) => void;
  universals: UniversalDataState;
  fetchUniversals: (formData?: SearchFormData) => void;
}

const cFilteredHL = "#1C658C";
const cFiltered = "#398AB9";

const cDefaultHL = "#D8D2CB";
const cDefault = "#EEEEEE";

const cTextNormal = "black";
const cTextDimmed = "darkgrey";

const pageEntity = "map";
// Menu: Manuscript admin
const MapPage: React.FC<MapPage> = ({
  manuscripts,
  fetchManuscripts,
  manuscriptsUniversals,
  fetchManuscriptsUniversals,
  institutions,
  fetchInstitutions,
  universals,
  fetchUniversals,
}) => {
  const { t } = useTranslation(pageEntity);

  const ref = useRef(null);
  const [wDashboard, setWDashboard] = useState<number>(0);

  const hTop = 600;
  const hBottom = 300;

  const wLeft = 300;
  const wRight = 400;
  const wMiddle = useMemo(() => {
    return wDashboard - wLeft - wRight;
  }, [wDashboard]);

  useEffect(() => {
    const handleResize = () => {
      if (ref && ref.current) {
        //@ts-ignore
        setWDashboard(parseInt(ref?.current?.offsetWidth) as number);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [filters, setFilters] = useState<IFilter>(defaultFilter);

  useEffect(() => {
    fetchManuscripts();
    fetchInstitutions();
    fetchManuscriptsUniversals();
    fetchUniversals();
  }, [
    fetchManuscripts,
    fetchInstitutions,
    fetchManuscriptsUniversals,
    fetchUniversals,
  ]);

  const dataManuscripts = useMemo(
    () => (manuscripts.isFetching ? [] : manuscripts.data),
    [manuscripts]
  );

  const dataInstitutions = useMemo(
    () => (institutions.isFetching ? [] : institutions.data),
    [institutions]
  );

  const dataUniversals = useMemo(() => {
    const data: any[] = [];
    if (!universals.isFetching && universals.data) {
      const universalsData: Universal[] = (universals as any).data;
      universalsData.forEach((universal: any) => {
        const universalData = { ...universal };

        universalData["locations"] = [];

        universal.manuscripts.forEach((unM: any) => {
          const manuscript: any = dataManuscripts.find(
            (m) => m.id === unM.manuscript_id
          );

          if (manuscript) {
            const institution = dataInstitutions.find(
              (i) => i.gps === manuscript.gps
            );

            if (institution) {
              const location = institution.name.split(",")[0];
              if (!universalData["locations"].includes(location)) {
                universalData["locations"].push(location);
              }
            }
          }
        });

        data.push(universalData);
      });
    }
    return data;
  }, [manuscriptsUniversals, dataManuscripts, dataInstitutions, universals]);

  const dataUniversalsUnique = useMemo(() => {
    const ids = [...new Set(dataUniversals.map((u) => u.id))];
    return ids.map((uniqueId) => {
      return dataUniversals.find((du) => du.id === uniqueId);
    });
  }, [dataUniversals]);

  // return ids of universals selected by filters
  const selectedUniversalsIds = useMemo(() => {
    return dataUniversalsUnique
      .filter((universal) => {
        if (filters.spatial) {
          return universal.locations.includes(filters.spatial);
        } else if (filters.author) {
          return universal.author_names === filters.author;
        } else if (filters.sigla) {
          return universal.sigla2.split(".")[0] === filters.sigla;
        } else if (filters.date) {
          const dateCat = dateDict.find(
            (d: any) => d.date === universal.origin
          );
          if (dateCat) {
            return dateCat[filters.date] === 1;
          }
          return false;
        } else {
          return true;
        }
      })
      .map((u) => u.id);
  }, [filters, dataUniversals]);

  const dataLocation = useMemo(() => {
    const data: object[] = [];
    dataInstitutions
      .filter((di: any) => di.gps.split(",")[0])
      .forEach((institution: any) => {
        const locationName = institution.name.split(",")[0];

        if (!data.find((d: any) => d.name === locationName)) {
          data.push({
            name: locationName,
            institutions: [
              {
                institution,
              },
            ],
            universals: [],
            x: parseFloat(institution.gps.split(",")[0]),
            y: parseFloat(institution.gps.split(",")[1]),
          });
        } else {
          (
            data.find((l: any) => l.name === locationName) as any
          )?.institutions?.push(institution);
        }
      });

    dataUniversals.forEach((universal: any) => {
      universal.locations.forEach((uLoc: any) => {
        const location = data.find((d: any) => d.name === uLoc);
        if (location) {
          (location as any).universals.push(universal);
        }
      });
    });

    return data;
  }, [dataInstitutions, dataUniversals]);

  const dataAuthors = useMemo(() => {
    const data: any[] = [];
    dataUniversalsUnique.forEach((universals: any) => {
      const universalsAuthor = universals.author_names;

      // is author in data
      const authorData = data.find((d) => d.name === universalsAuthor);

      if (authorData) {
        authorData.universals.push(universals);
      } else {
        data.push({
          name: universalsAuthor,
          universals: [universals],
        });
      }
    });
    return data;
  }, [dataInstitutions, dataUniversalsUnique]);

  const dataSigla = useMemo(() => {
    const data: { name: string; id: string; universals: any[] }[] = [
      { name: "Opera Philosophica Johannis Wyclif", id: "I", universals: [] },
      { name: "Opera et opuscula de universalibus", id: "II", universals: [] },
      {
        name: "Expositiones et quaestiones in librum Praedicamentorum Aristotelis",
        id: "III",
        universals: [],
      },
      {
        name: "Expositiones et quaestiones in logicam novam",
        id: "IV",
        universals: [],
      },
      {
        name: "Expositiones et quaestiones in philosophiam naturalem",
        id: "V",
        universals: [],
      },
      {
        name: "Expositiones et quaestiones in metaphysicam",
        id: "VI",
        universals: [],
      },
    ];
    dataUniversalsUnique.forEach((universals: any) => {
      const siglaVals = universals.sigla2.split(".");
      const siglaCat = siglaVals[0];

      // is author in data
      const siglaCatData = data.find((d) => d.id === siglaCat);

      if (siglaCatData) {
        siglaCatData.universals.push(universals);
      } else {
        //console.log("invalid sigla", universals);
      }
    });
    return data;
  }, [dataInstitutions, dataUniversalsUnique]);

  const dataDate = useMemo(() => {
    const data: any[] = [];

    Object.keys(dateDict[0]).forEach((dateCat: string) => {
      if (dateCat !== "date") {
        data.push({ dateCat: dateCat, universals: [] });
      }
    });

    dataUniversalsUnique.forEach((universals: any) => {
      const dateCatObj = dateDict.find((dateDictCat: any) => {
        return universals.origin === dateDictCat.date;
      });
      if (dateCatObj) {
        const dateKeys = Object.keys(dateCatObj).filter(
          (dateCatObjKey: any) => dateCatObj[dateCatObjKey] === 1
        );

        dateKeys.forEach((dateCatKey: string) => {
          const keyInData = data.find(
            (dataRow: any) => dataRow.dateCat === dateCatKey
          );
          if (keyInData) {
            keyInData.universals.push(universals);
          }
        });
      }
    });

    return data;
  }, [dataUniversalsUnique]);

  const handleFilterSpatial = (localityName: string | false) => {
    setFilters({
      spatial: localityName,
      date: false,
      author: false,
      sigla: false,
    });
  };
  const handleFilterAuthor = (authorName: string | false) => {
    setFilters({
      spatial: false,
      date: false,
      author: authorName,
      sigla: false,
    });
  };
  const handleFilterSigla = (siglaId: string | false) => {
    setFilters({
      spatial: false,
      date: false,
      author: false,
      sigla: siglaId,
    });
  };
  const handleFilterDate = (dateCat: string | false) => {
    setFilters({
      spatial: false,
      date: dateCat,
      author: false,
      sigla: false,
    });
  };

  return (
    <>
      <PageTitle>{t("title")}</PageTitle>
      <StyledDashboard
        ref={ref}
        hTop={hTop}
        hBottom={hBottom}
        wLeft={wLeft}
        wMiddle={wMiddle}
        wRight={wRight}
      >
        <Map
          locations={dataLocation}
          selectedLocality={filters.spatial}
          filtered={selectedUniversalsIds}
          onFilter={handleFilterSpatial}
          height={hTop}
          width={wLeft + wMiddle}
        />
        <AuthorList
          authors={dataAuthors}
          selectedAuthor={filters.author}
          filtered={selectedUniversalsIds}
          onFilter={handleFilterAuthor}
          height={hBottom + hTop}
          width={wRight}
        />
        <SiglaChart
          data={dataSigla}
          selectedSiglaId={filters.sigla}
          filtered={selectedUniversalsIds}
          onFilter={handleFilterSigla}
          height={hBottom}
          width={wLeft}
        />
        <Timeline
          data={dataDate}
          selectedDate={filters.date}
          filtered={selectedUniversalsIds}
          onFilter={handleFilterDate}
          width={wMiddle}
          height={hBottom}
        />
      </StyledDashboard>
    </>
  );
};

/**
 * Map
 */

interface Map {
  locations: any;
  filtered: string[];
  selectedLocality: string | false;
  onFilter: (locality: string | false) => void;
  width: number;
  height: number;
}

const Map: React.FC<Map> = ({
  locations,
  filtered,
  selectedLocality,
  onFilter,
}) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState<L.Map | false>(false);
  const [ll, setLl] = useState<[number, number]>([54, 15]);
  const [zoom, setZoom] = useState<number>(5);

  const [highlightedLocation, setHighlightedLocation] =
    useState<string | false>(false);
  const [allLocalities, setAllLocalities] = useState<L.LayerGroup>(
    new L.LayerGroup()
  );
  const [filteredLocalities, setFilteredLocalities] = useState<L.LayerGroup>(
    new L.LayerGroup()
  );
  const [selectedLocalities, setSelectedLocalities] = useState<L.LayerGroup>(
    new L.LayerGroup()
  );

  const sortedLocation = useMemo(() => {
    const locationsToSort =
      locations && locations.length !== 0 ? [...locations] : [];

    locationsToSort.sort((a: any, b: any) => {
      if (a.universals.length < b.universals.length) {
        return 1;
      } else {
        return -1;
      }
    });

    return locationsToSort;
  }, [locations]);

  useEffect(() => {
    const mapDiv = document.getElementById("map");

    if (mapDiv) {
      setMap(
        L.map(mapDiv, {
          minZoom: 5,
          maxZoom: 20,
          zoom: zoom,
          center: ll,
          maxBounds: [
            [20, -30],
            [70, 50],
          ],

          layers: [
            L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
              maxZoom: 19,
              attribution:
                '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
            }),
          ],
          attributionControl: false,
        })
      );
    }
  }, [mapContainer.current]);

  const radius = (no: number) => {
    return 5000 * Math.sqrt(no * 1) + 20000;
  };

  // move map
  useEffect(() => {
    if (map) {
      map.flyTo(ll);
      filteredLocalities.addTo(map);
      allLocalities.addTo(map);
    }
  }, [map, ll]);

  useEffect(() => {
    if (map) {
      map.removeLayer(allLocalities);
      allLocalities.clearLayers();

      sortedLocation
        .filter((l: any) => l.universals.length > 0)
        .forEach((location: any) => {
          const selected = selectedLocality === location.name;
          const highlighted = highlightedLocation === location.name;

          const marker = L.circle([location.x, location.y], {
            radius: radius(location.universals.length),
            stroke: true,
            fillColor: highlighted ? cDefaultHL : cDefault,
            fillOpacity: 1,
            color: selected ? "black" : "white",
            weight: selected ? 3 : 1.5,
            //className: JSON.stringify(location),
          })
            .on("click", (e) => {
              onFilter(
                location.name === selectedLocality ? false : location.name
              );
            })
            .on("mouseover", (e) => {
              setHighlightedLocation(location.name);
            })
            .on("mouseout", (e) => {
              setHighlightedLocation(false);
            })
            .bindTooltip(location.name, { permanent: selected })
            .openTooltip();
          allLocalities.addLayer(marker);
        });

      allLocalities.addTo(map);
    }
  }, [locations, filtered, highlightedLocation]);

  useEffect(() => {
    if (map) {
      map.removeLayer(filteredLocalities);
      filteredLocalities.clearLayers();

      sortedLocation
        .filter(
          (l: any) =>
            l.universals.filter((u: any) => filtered.includes(u.id)).length > 0
        )
        .forEach((location: any) => {
          const selected = selectedLocality === location.name;
          const highlighted = highlightedLocation === location.name;

          const noFiltered = location.universals.filter((u: any) =>
            filtered.includes(u.id)
          ).length;

          const allFiltered = noFiltered === location.universals.length;

          const marker = L.circle([location.x, location.y], {
            radius: radius(noFiltered),
            fillColor: highlighted ? cFilteredHL : cFiltered,
            fillOpacity: 1,
            color: selected ? "black" : "white",
            weight: selected ? 3 : 1.5,
            //className: JSON.stringify(location),
          })
            .on("click", (e) => {
              onFilter(
                location.name === selectedLocality ? false : location.name
              );
            })
            .on("mouseover", (e) => {
              setHighlightedLocation(location.name);
            })
            .on("mouseout", (e) => {
              setHighlightedLocation(false);
            })
            .bindTooltip(location.name, { permanent: false })
            .openTooltip();
          filteredLocalities.addLayer(marker);
        });

      filteredLocalities.addTo(map);
    }
  }, [locations, filtered, highlightedLocation]);

  return (
    <StyledMapWrapper>
      <div id="map" className="map" />
    </StyledMapWrapper>
  );
};

/**
 * Author list
 */
interface AuthorList {
  authors: any[];
  selectedAuthor: string | false;
  filtered: string[];
  onFilter: (authorId: string | false) => void;
  width: number;
  height: number;
}

const AuthorList: React.FC<AuthorList> = ({
  authors,
  filtered,
  selectedAuthor,
  onFilter,
  width,
}) => {
  const { t } = useTranslation(pageEntity);
  const [highlightedAuthor, setHighlightedAuthor] =
    useState<false | string>(false);
  const sortedAuthors = useMemo(() => {
    const sorted = authors.map((s) => {
      return {
        ...s,
        selected: selectedAuthor && selectedAuthor === s.name,
        all: s.universals.length,
        filtered: s.universals.filter((u: any) => filtered.includes(u.id))
          .length,
      };
    });
    //      .filter((s: any) => s.all > 2);

    sorted.sort((a: any, b: any) => {
      return a.all > b.all ? -1 : 1;
      // if (a.filtered === b.filtered) {
      //   return a.all > b.all ? -1 : 1;
      // } else {
      //   return a.filtered > b.filtered ? -1 : 1;
      // }
    });

    return sorted;
  }, [authors, filtered, selectedAuthor]);

  const noMax = useMemo(() => {
    return Math.max(...sortedAuthors.map((s) => s.all));
  }, [sortedAuthors]);

  return (
    <StyledAuthorList>
      <div className="box-title">{t("authors")}</div>
      {sortedAuthors.map((author: any) => {
        const selected = author.selected;
        const filtered = author.filtered > 0;
        const highlighted =
          highlightedAuthor !== false && highlightedAuthor === author.name;

        const wNames = 250;
        const wBoxes = (width - wNames) * 0.5;
        const noFiltered = author.filtered;
        const noAll = author.all;

        const wFiltered = Math.ceil((noFiltered / noMax) * wBoxes);
        const wAll = Math.ceil((noAll / noMax - noFiltered / noMax) * wBoxes);

        return (
          <StyledAuthorListItem
            key={author.name}
            onClick={() => {
              onFilter(author.name);
            }}
            onMouseOver={() => {
              setHighlightedAuthor(author.name);
            }}
            onMouseOut={() => {
              setHighlightedAuthor(false);
            }}
            selected={selected}
            highlighted={highlighted}
            //highlighted={false}
            filtered={filtered}
            wFiltered={wFiltered}
            wAll={wAll}
            wNames={wNames}
          >
            <div className="name">{author.name}</div>
            <div className="count"> {`${author.filtered}/${author.all}`}</div>
            <div className="box">
              {wFiltered > 0 && (
                <div className="box-filtered" style={{ width: wFiltered }} />
              )}
              {wAll > 0 && <div className="box-all" style={{ width: wAll }} />}
            </div>
          </StyledAuthorListItem>
        );
      })}
    </StyledAuthorList>
  );
};

/**
 * Sigla
 */
interface SiglaChart {
  data: any[];
  selectedSiglaId: string | false;
  filtered: string[];
  onFilter: (authorId: string | false) => void;
  width: number;
  height: number;
}

const SiglaChart: React.FC<SiglaChart> = ({
  data,
  filtered,
  selectedSiglaId,
  onFilter,
  width,
  height,
}) => {
  const { t } = useTranslation(pageEntity);
  const [highlightedArc, setHighlightedArc] = useState<string | false>(false);
  const siglaPieSegments = useMemo(() => {
    const noUniversals = data
      .map((d: any) => d.universals.length)
      .reduce((a, b) => a + b, 0);
    const oneUniDegree = 360 / noUniversals;
    let startAngle = 0;

    return data.map((dataSigla: any, di: number) => {
      const segmentAngle = dataSigla.universals.length * oneUniDegree;

      const labelX = Math.cos(
        (startAngle + segmentAngle / 2) * (Math.PI / 180)
      );
      const labelY = Math.sin(
        (startAngle + segmentAngle / 2) * (Math.PI / 180)
      );

      startAngle += segmentAngle;

      return {
        ...dataSigla,
        allNo: dataSigla.universals.length,
        filteredNo: dataSigla.universals.filter((u: any) =>
          filtered.includes(u.id)
        ).length,
        angle: segmentAngle,
        rotation: startAngle - segmentAngle,
        labelX,
        labelY,
      };
    });
  }, [data, filtered]);

  const selectedSigla = useMemo(() => {
    return selectedSiglaId
      ? siglaPieSegments.find((siglaCat: any) => {
          return siglaCat.id === selectedSiglaId;
        })
      : false;
  }, [selectedSiglaId, data]);

  const highlightedSigla = useMemo(() => {
    return highlightedArc
      ? siglaPieSegments.find((siglaCat: any) => {
          return siglaCat.id === highlightedArc;
        })
      : false;
  }, [highlightedArc, data]);

  const centerX = useMemo(() => {
    return width / 2 - 25;
  }, [width]);
  const centerY = useMemo(() => {
    return height / 2 - 25 + 50;
  }, [height]);

  const radius = useMemo(() => {
    return height * 0.25;
  }, [height]);

  return (
    <StyledSiglaChart>
      <div className="box-title">{t("sigla")}</div>
      {selectedSigla && (
        <div className="box-selected">{`${selectedSigla.id}-${selectedSigla.name}`}</div>
      )}
      <Stage width={width} height={height}>
        <Layer key="Arcs">
          {siglaPieSegments
            .filter((dataSigla: any) => dataSigla.angle > 0)
            .map((dataSigla: any, di: number) => {
              const selected = selectedSigla.id === dataSigla.id;
              const filtered = dataSigla.filteredNo > 0;
              const highlighted = highlightedArc === dataSigla.id;
              return (
                <Group
                  key={di}
                  onMouseOver={() => {
                    setHighlightedArc(dataSigla.id);
                  }}
                  onMouseOut={() => {
                    setHighlightedArc(false);
                  }}
                >
                  <Arc
                    angle={dataSigla.angle}
                    rotation={dataSigla.rotation}
                    x={centerX}
                    y={centerY}
                    fill={highlighted ? cDefaultHL : cDefault}
                    innerRadius={0}
                    outerRadius={radius}
                    strokeWidth={3}
                    opacity={1}
                    lineJoin="round"
                    stroke="white"
                    onClick={() => {
                      onFilter(dataSigla.id);
                    }}
                  />
                  <Arc
                    angle={dataSigla.angle}
                    rotation={dataSigla.rotation}
                    x={centerX}
                    y={centerY}
                    fill={highlighted ? cFilteredHL : cFiltered}
                    lineJoin="round"
                    innerRadius={0}
                    outerRadius={
                      radius * Math.sqrt(dataSigla.filteredNo / dataSigla.allNo)
                    }
                    strokeWidth={1}
                    opacity={1}
                    onClick={() => {
                      onFilter(dataSigla.id);
                    }}
                  />
                </Group>
              );
            })}
          {selectedSigla && (
            <Group
              key="selected"
              onMouseOver={() => {
                setHighlightedArc(selectedSigla.id);
              }}
              onMouseOut={() => {
                setHighlightedArc(false);
              }}
            >
              <Arc
                angle={selectedSigla.angle}
                rotation={selectedSigla.rotation}
                x={centerX}
                y={centerY}
                fill={
                  highlightedArc === selectedSiglaId ? cFilteredHL : cFiltered
                }
                stroke={"black"}
                innerRadius={0}
                outerRadius={radius}
                strokeWidth={3}
                lineJoin="round"
                // shadowBlur={2}
                // shadowEnabled={true}
                onClick={() => {
                  onFilter(false);
                }}
              />
            </Group>
          )}

          {siglaPieSegments
            .filter((dataSigla: any) => dataSigla.angle > 0)
            .map((dataSigla: any, di: number) => {
              const selected = selectedSigla.id === dataSigla.id;
              const filtered = dataSigla.filteredNo > 0;
              const highlighted = highlightedArc === dataSigla.id;
              return (
                <Group
                  key={di}
                  onMouseOver={() => {
                    setHighlightedArc(dataSigla.id);
                  }}
                  onMouseOut={() => {
                    setHighlightedArc(false);
                  }}
                >
                  <Text
                    text={dataSigla.id}
                    align="center"
                    width={30}
                    fontStyle={selected || highlighted ? "bolder" : "normal"}
                    fontSize={selected ? 12 : 12}
                    fillAfterStrokeEnabled
                    lineJoin={"round"}
                    lineCap={"round"}
                    stroke={"white"}
                    strokeWidth={3}
                    x={centerX + dataSigla.labelX * 80 - 15}
                    y={centerY + dataSigla.labelY * 80}
                    fill={filtered ? cTextNormal : cTextDimmed}
                  />
                </Group>
              );
            })}
        </Layer>
      </Stage>
      {highlightedSigla && (
        <div className="box-highlighted">{`${highlightedSigla.id}-${highlightedSigla.name}`}</div>
      )}
    </StyledSiglaChart>
  );
};

/**
 * Timeline
 */
interface Timeline {
  data: any[];
  filtered: string[];
  selectedDate: string | false;
  onFilter: (authorId: string | false) => void;
  width: number;
  height: number;
}

const Timeline: React.FC<Timeline> = ({
  data,
  filtered,
  selectedDate,
  onFilter,
  width,
  height,
}) => {
  const { t } = useTranslation(pageEntity);

  const [highlightedBar, setHighlightedBar] = useState<string | false>(false);
  const margins = {
    l: 20,
    r: 20,
    t: 20,
    b: 20,
  };

  const hLabels = 100;
  const hBars = height - hLabels - margins.t - margins.b;
  const hBarOneY = useMemo(() => {
    const maxLen = Math.max(...data.map((d: any) => d.universals.length));
    return hBars / maxLen;
  }, [data]);

  const wBarsGap = 5;
  const wBars = useMemo(() => {
    return width - margins.l - margins.r;
  }, [width, data]);

  const wBar = useMemo(() => {
    return (wBars - data.length * wBarsGap) / data.length;
  }, [wBars, data]);

  const dateBars = useMemo(() => {
    return data.map((dateGroup: any, di: number) => {
      const noAll = dateGroup.universals.length;
      const noFiltered = dateGroup.universals.filter((u: any) =>
        filtered.includes(u.id)
      ).length;

      const x = margins.l + di * (wBar + wBarsGap);

      const hFiltered = noFiltered * hBarOneY;
      const yFiltered = margins.t + hBars - hFiltered;
      const hAll = noAll * hBarOneY;
      const yAll = margins.t + hBars - hAll;

      return {
        ...dateGroup,
        noAll,
        noFiltered,
        hFiltered,
        yFiltered,
        hAll,
        yAll,
        x,
      };
    });
  }, [data, filtered, wBar]);

  return (
    <StyledTimeline>
      <div className="box-title">{t("time")}</div>
      <Stage width={width} height={height}>
        <Layer key="bars">
          {dateBars
            .filter((dateBar) => dateBar.hAll)
            .map((dateBar) => {
              const selected = selectedDate === dateBar.dateCat;
              const filtered = dateBar.noFiltered > 0;
              const highlighted = highlightedBar === dateBar.dateCat;
              const allInFilter = dateBar.noFiltered === dateBar.noAll;

              return (
                <Group
                  key={dateBar.dateCat}
                  onMouseOver={() => {
                    setHighlightedBar(dateBar.dateCat);
                  }}
                  onMouseOut={() => {
                    setHighlightedBar(false);
                  }}
                  onClick={() => {
                    onFilter(dateBar.dateCat);
                  }}
                >
                  <Rect
                    x={dateBar.x}
                    y={dateBar.yAll}
                    width={wBar + 1}
                    height={dateBar.hAll}
                    fill={highlighted ? cDefaultHL : cDefault}
                    strokeWidth={selected ? 3 : 0}
                    stroke="black"
                  />
                  <Rect
                    x={dateBar.x}
                    y={dateBar.yFiltered}
                    width={wBar}
                    height={dateBar.hFiltered}
                    fill={highlighted ? cFilteredHL : cFiltered}
                  />
                  <Text
                    x={dateBar.x + wBar / 2 + 5}
                    y={height - margins.b - hLabels + 10}
                    fill={filtered ? cTextNormal : cTextDimmed}
                    fontStyle={selected || highlighted ? "bold" : "normal"}
                    fontFamily="Ubuntu"
                    fontSize={10}
                    rotation={90}
                    text={dateBar.dateCat}
                  />
                  {!allInFilter && (
                    <Text
                      y={dateBar.yAll - 20}
                      x={dateBar.x - 5}
                      width={wBar + 10}
                      align="center"
                      verticalAlign="bottom"
                      fillAfterStrokeEnabled
                      lineJoin={"round"}
                      lineCap={"round"}
                      stroke={"white"}
                      fontFamily="Ubuntu"
                      fontSize={10}
                      fill={filtered ? cTextNormal : cTextDimmed}
                      fontStyle={selected || highlighted ? "bold" : "normal"}
                      text={`${dateBar.noFiltered}/`}
                    />
                  )}
                  <Text
                    y={dateBar.yAll - 11}
                    x={dateBar.x - 5}
                    width={wBar + 10}
                    align="center"
                    verticalAlign="bottom"
                    fillAfterStrokeEnabled
                    lineJoin={"round"}
                    lineCap={"round"}
                    stroke={"white"}
                    fontFamily="Ubuntu"
                    fontSize={10}
                    fill={filtered ? cTextNormal : cTextDimmed}
                    fontStyle={selected || highlighted ? "bold" : "normal"}
                    text={`${dateBar.noAll}`}
                  />
                </Group>
              );
            })}
        </Layer>
      </Stage>
    </StyledTimeline>
  );
};

// styled components
interface IStyledDashboard {
  hTop: number;
  hBottom: number;
  wLeft: number;
  wMiddle: number;
  wRight: number;
}

const StyledDashboard = styled.div<IStyledDashboard>`
  display: grid;
  grid-template-columns: ${({ wLeft, wMiddle, wRight }) =>
    `${wLeft}px ${wMiddle}px ${wRight}px`};
  grid-template-rows: ${({ hTop, hBottom }) => `${hTop}px ${hBottom}px`};
  max-width: 1500px;
  min-width: 1000px;
`;

// boxes

const StyledMapWrapper = styled.div`
  grid-column: 1/3;
  grid-row: 1/2;
  gap: 0;

  .leaflet-container {
    height: 100%;
    width: 100%;

    .leaflet-tile-pane .leaflet-layer {
      filter: hue-rotate(150deg) sepia(0.1) saturate(0.5) invert(0.85);
    }
    .leaflet-pane > svg path.leaflet-interactive {
      mix-blend-mode: normal;
    }
  }
`;

const StyledAuthorList = styled.div`
  grid-row: 1/3;
  grid-column: 3;
  z-index: 200;
  background-color: white;
  max-width: 100%;
  overflow-y: scroll;
  font-family: Ubuntu;
  font-size: 0.8em;

  .box-title {
    font-size: 1.5em;
    text-align: center;
    font-weight: bold;
  }
`;

const StyledSiglaChart = styled.div`
  grid-row: 2;
  grid-column: 1;
  z-index: 500;
  overflow: none;
  font-family: Ubuntu;
  font-size: 0.8em;
  position: relative;
  .box-title {
    font-size: 1.5em;
    font-weight: bold;
    position: absolute;
    left: 1em;
    top: 1em;
  }
  .box-selected {
    font-size: 1em;
    position: absolute;
    left: 1.5em;
    top: 3.5em;
  }
  .box-highlighted {
    font-size: 1em;
    position: relative;
    left: 1.5em;
    top: -1.5em;
  }
`;

const StyledTimeline = styled.div`
  grid-row: 2;
  grid-column: 2;
  z-index: 500;
  overflow: none;
  font-family: Ubuntu;
  font-size: 0.8em;

  .box-title {
    font-size: 1.5em;
    font-weight: bold;
    position: relative;
    left: 1em;
    top: 1em;
  }
`;

// authors

interface IStyledAuthorListItem {
  selected: boolean;
  highlighted: boolean;
  filtered: boolean;

  wFiltered: number;
  wAll: number;

  wNames: number;
}

const makeTextHalo = (color: string) => {
  return `${color} 1px 1px 1px, ${color} -1px -1px 1px,
  ${color} -1px 1px 1px, ${color} 1px -1px 1px `;
};

const StyledAuthorListItem = styled.div<IStyledAuthorListItem>`
  cursor: pointer;
  display: grid;
  grid-template-columns: ${({ wNames }) => wNames}px 0 auto;
  align-items: center;
  margin-left: 1em;

  .checkbox,
  .name,
  .count {
    z-index: 2;
  }

  .name,
  .count {
    color: ${({ filtered }) => (filtered ? cTextNormal : cTextDimmed)};
    font-weight: ${({ selected, highlighted }) =>
      selected || highlighted ? 600 : 300};
  }

  .count {
    text-align: right;
    margin-left: 0.3em;
    font-size: 0.8em;
    text-shadow: ${({ highlighted }) => makeTextHalo("white")};
  }
  .box {
    overflow: hidden;
    z-index: 1;
  }

  .name {
    width: ${({ wNames }) => wNames - 10}px;
    word-break: break-word;
    text-align: right;
  }

  .box .box-filtered,
  .box .box-all {
    height: 1.5em;
    z-index: 1;
    display: inline-flex;
  }

  .box .box-filtered {
    background: ${({ highlighted }) => (highlighted ? cFilteredHL : cFiltered)};
  }
  .box .box-all {
    background: ${({ highlighted }) => (highlighted ? cDefaultHL : cDefault)};
  }
`;

interface IStyledAuthorBoxDiv {
  color: string;
  width: number;
}

const mapStateToProps = ({
  manuscriptsUniversals,
  manuscripts,
  persons,
  institutions,
  universals,
}: StateFromProps): StateToProps => ({
  manuscriptsUniversals,
  manuscripts,
  persons,
  institutions,
  universals,
});

export default connect(mapStateToProps, {
  fetchManuscriptsUniversals,
  fetchManuscripts,
  fetchPersons,
  fetchInstitutions,
  fetchUniversals,
})(MapPage);

interface StateFromProps {
  manuscriptsUniversals: ManuscriptsUniversalsDataState;
  manuscripts: ManuscriptsDataState;
  persons: PersonsDataState;
  institutions: InstitutionsDataState;
  universals: UniversalDataState;
}

interface StateToProps {
  manuscriptsUniversals: ManuscriptsUniversalsDataState;
  manuscripts: ManuscriptsDataState;
  persons: PersonsDataState;
  institutions: InstitutionsDataState;
  universals: UniversalDataState;
}
