import {
  FetchInstitutionsRequestAction,
  FetchInstitutionsSuccessAction,
  FetchInstitutionsFailureAction,
  FETCH_INSTITUTIONS_REQUEST,
  FETCH_INSTITUTIONS_SUCCESS,
  FETCH_INSTITUTIONS_FAILURE,
} from "redux/types";
import { InstitutionsDataState } from "common/types";

type ValidAction =
  | FetchInstitutionsRequestAction
  | FetchInstitutionsSuccessAction
  | FetchInstitutionsFailureAction;

const institutions = (
  state = { data: [] },
  action: ValidAction
): InstitutionsDataState => {
  switch (action.type) {
    case FETCH_INSTITUTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_INSTITUTIONS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default institutions;
