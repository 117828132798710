import React from "react";
import { useTable, useSortBy, usePagination, Column } from "react-table";
import DotLoader from "react-spinners/DotLoader";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
  globalBlack,
  globalWhite,
  globalLightGrey,
  globalRadius,
} from "common/constants";
import { useAuth0 } from "@auth0/auth0-react";

const Pagination = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
`;
const PageNumber = styled.div`
  margin: 0 0.8rem;
`;
const Records = styled.p`
  margin: 0 1rem;
`;
const PageInput = styled.input`
  height: 2.4rem;
  width: 5rem;
  text-align: center;
  border-radius: 0;
  border: 1px solid black;
  margin-right: 0.5rem;
  outline: none;
`;
const PaginationSelect = styled.select`
  height: 2.4rem;
  border-radius: 0;
  border: 1px solid black;
  margin-left: 0.5rem;
  outline: none;
`;
interface PageButton {
  disabled?: boolean;
}
const PageButton = styled.button`
  font-family: "LinLibertine";
  background-color: ${({ disabled }) => (disabled ? "white" : "black")};
  color: ${({ disabled }) => (disabled ? "black" : "white")};
  padding: 0 0.7rem 0.1rem 0.7rem;
  height: 2.4rem;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  margin: 0 0.1rem;
  border-radius: ${globalRadius};
  font-size: 1.7rem;
  border: 1px solid black;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  font-weight: bold;

  :focus {
    outline: none;
  }
`;
const TableContainer = styled.div`
  overflow: auto;
  position: relative;
  width: 100%;
`;
const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
`;
interface RowProps {
  isOdd: boolean;
}
const TableRow = styled.tr<RowProps>`
  border-bottom: 1px solid grey;
  /* background-color: ${({ isOdd }) =>
    isOdd ? `${globalLightGrey}` : `${globalWhite}`}; */
`;
const DataCell = styled.td`
  padding: 0.5em 0.75em;
  text-align: left;
`;
const HeadingCell = styled.th`
  padding: 0.5em 0.75em;
  text-align: left;
`;

const HeadingRow = styled.thead`
  border-bottom: 1px solid ${globalBlack};
  /* background-color: ${globalWhite}; */
`;

interface Table {
  data: {}[];
  columns: Column<{}>[];
  showLoading?: boolean;
}

export const Table: React.FC<Table> = ({ data, columns, showLoading }) => {
  // const { isAuthenticated } = useAuth0();
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      disableSortRemove: true,
      initialState: {
        pageIndex: 0,
        pageSize: 100,
        hiddenColumns: ["id", "manuscript_id", "edition_link", "digital_copy"],
      },
    },
    useSortBy,
    usePagination
  );
  const { t } = useTranslation("table");

  const getPaging = () => (
    <Pagination>
      <Records>
        {"Records: "}
        {data.length}
      </Records>
      <PageInput
        type="number"
        min="1"
        max={pageOptions.length}
        defaultValue={pageIndex + 1}
        onChange={(e) => {
          const page = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(page);
        }}
      />
      <PageButton
        type="button"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      >
        {"<<"}
      </PageButton>
      <PageButton
        type="button"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
      >
        {"<"}
      </PageButton>

      <PageNumber>
        <strong>
          {pageIndex + 1}/{pageOptions.length}
        </strong>
      </PageNumber>

      <PageButton
        type="button"
        onClick={() => nextPage()}
        disabled={!canNextPage}
      >
        {">"}
      </PageButton>
      <PageButton
        type="button"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        {">>"}
      </PageButton>
      <PaginationSelect
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
      >
        {[5, 10, 50, 100, 500].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </PaginationSelect>
    </Pagination>
  );

  return (
    <TableContainer>
      {getPaging()}
      <StyledTable {...getTableProps()}>
        <HeadingRow>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
              {headerGroup.headers.map((column, key) => (
                <HeadingCell
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={key}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ↑" : " ↓") : ""}
                  </span>
                </HeadingCell>
              ))}
            </tr>
          ))}
        </HeadingRow>
        <tbody {...getTableBodyProps()}>
          {page.map((row, key) => {
            prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps()}
                key={key}
                isOdd={Boolean(key % 2)}
              >
                {row.cells.map((cell, key) => {
                  return (
                    <DataCell {...cell.getCellProps()} key={key}>
                      {cell.render("Cell")}
                    </DataCell>
                  );
                })}
              </TableRow>
            );
          })}
        </tbody>
        <tfoot></tfoot>
      </StyledTable>
      {data.length < 1 && !showLoading && t("noRecords")}
      {/* {"Server error"} */}
      <DotLoader
        size={70}
        color="#000"
        loading={showLoading || false}
        css={`
          margin: 3rem auto;
        `}
      />
      {getPaging()}
    </TableContainer>
  );
};
