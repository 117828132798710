import React, { useEffect } from "react";
import { Provider } from "react-redux";
import {
  Switch,
  Route,
  BrowserRouter,
  RouteComponentProps,
} from "react-router-dom";
import store from "redux/store";
import DotLoader from "react-spinners/DotLoader";

import {
  Page,
  PersonDetail,
  InstitutionDetail,
  UniversalDetail,
  ManuscriptDetail,
} from "components";
import {
  InstitutionPage,
  UniversalPage,
  ManuscriptPage,
  PersonPage,
  HomePage,
  AboutPage,
  SiglaPage,
  ManuscriptUniversalPage,
  MapPage,
} from "pages";
import Auth0ProviderWithHistory from "auth/Auth0ProviderWithHistory";
import { ProtectedRoute } from "auth/ProtectedRoute";
import { GlobalStyle } from "Theme/global";

import ReactGA from 'react-ga';
import CookieConsent from "react-cookie-consent";

const App: React.FC = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Auth0ProviderWithHistory>
          <GlobalStyle />
          <Page>
            <Switch>
              <Route path="/about" component={AboutPage} />
              <Route path="/sigla" component={SiglaPage} />
              <Route
                path="/persons/:id/new"
                children={<PersonDetail isNew />}
              />
              <Route
                path="/persons/:id/edit"
                children={<PersonDetail isEdit />}
              />
              <Route path="/persons/:id" children={<PersonDetail />} />
              <Route path="/persons" component={PersonPage} />
              {/* <ProtectedRoute component={PersonPage} /> */}
              <Route
                path="/institutions/:id/new"
                children={<InstitutionDetail isNew />}
              />
              <Route
                path="/institutions/:id/edit"
                children={<InstitutionDetail isEdit />}
              />
              <Route
                path="/institutions/:id"
                children={<InstitutionDetail />}
              />
              <Route path="/institutions" component={InstitutionPage} />

              <Route
                path="/universals/:id/new"
                children={<UniversalDetail isNew />}
              />
              <Route
                path="/universals/:id/edit"
                children={<UniversalDetail isEdit />}
              />
              <Route path="/universals/:id" children={<UniversalDetail />} />
              <Route path="/universals" component={UniversalPage} />
              <Route
                path="/manuscripts/:id/new"
                children={<ManuscriptDetail isNew />}
              />
              <Route
                path="/manuscripts/:id/edit"
                children={<ManuscriptDetail isEdit />}
              />
              <Route path="/manuscripts/:id" children={<ManuscriptDetail />} />
              <Route path="/manuscripts-admin" component={ManuscriptPage} />
              <Route path="/manuscripts" component={ManuscriptUniversalPage} />
              <Route exact path="/" component={HomePage} />
              <Route exact path="/map" component={MapPage} />
              <Route render={() => <h3>This route does not exist</h3>} />
            </Switch>
          </Page>
          <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
        </Auth0ProviderWithHistory>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
