import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import DotLoader from "react-spinners/DotLoader";

import { InstitutionDataState, Institution, UseParamsId } from "common/types";
import { fetchInstitution } from "redux/actions/entityActions";
import {
  EntityDetailBlockHeading as BlockHeading,
  EntityDetailRow as Row,
  EntityDetailColumn as Column,
  Input,
  Button,
  Toast,
} from "components";
import { updateEntity } from "api/updateEntity";
import { addEntity } from "api/addEntity";
import { toast } from "react-toastify";

interface InstitutionDetail {
  institution: InstitutionDataState;
  fetchInstitution: (id: string) => void;
  isNew?: boolean;
  isEdit?: boolean;
}
type FormData = {
  id: string;
  name: string;
  link: string;
  gps: string;
  grid: string;
  wikidata: string;
  gnd: string;
};

const pageEntity = "institution";
const InstitutionDetail: React.FC<InstitutionDetail> = ({
  institution,
  fetchInstitution,
  isNew,
  isEdit,
}) => {
  const { t } = useTranslation(pageEntity);
  const history = useHistory();
  const { id } = useParams<UseParamsId>();
  const { name, gps, link, grid, wikidata, gnd } = institution.data;
  const { register, handleSubmit, setValue } = useForm<FormData>();

  useEffect(() => {
    if (!isNew) {
      fetchInstitution(id);
    }
  }, [fetchInstitution, id, isNew]);

  useEffect(() => {
    if (isNew) {
      setValue("id", id);
      setValue("name", "");
      setValue("link", "");
      setValue("gps", "");
      setValue("grid", "");
      setValue("wikidata", "");
      setValue("gnd", "");
    } else if (!institution.isFetching) {
      setValue("id", id);
      setValue("name", name);
      setValue("gps", gps);
      setValue("link", link);
      setValue("grid", grid);
      setValue("wikidata", wikidata);
      setValue("gnd", gnd);
    }
  }, [setValue, isNew, id, name, gps, link, grid, wikidata, gnd, institution.isFetching]);

  const onSubmit = ({ name, gps, link }: FormData): void => {
    const institutionObject = {
      id: id,
      name: name,
      gps: gps,
      link: link,
      grid: grid, 
      wikidata: wikidata, 
      gnd: gnd
    };
    if (isNew) {
      addEntity(pageEntity, institutionObject as Institution).then(() => {
        history.push(`/${pageEntity}s/${id}/edit`);
        createNotify();
      });
    } else {
      updateEntity(pageEntity, institutionObject as Institution).then(() =>
        saveNotify()
      );
    }
  };

  const createNotify = () => toast.dark(t("createSuccess"));
  const saveNotify = () => toast.dark(t("saveSuccess"));

  return (
    <>
      {institution && !institution.isFetching ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <BlockHeading>{t("detailTitle")}</BlockHeading>
          <Row>
            <Column attr>
              <b>{t("id")}</b>
            </Column>
            <Column>{id}</Column>
          </Row>

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("name")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="name"
                  register={register}
                  required
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("name")}</b>
              </Column>
              <Column>{name}</Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("link")}</b>
              </Column>
              <Column>
                <Input underline type="text" name="link" register={register} />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("link")}</b>
              </Column>
              <Column><a href={link}>{link}</a></Column>
            </Row>
          )}

          {!isNew && !isEdit ? (
            <Row>
              <Column attr>
                <b>{t("gps")}</b>
              </Column>
              <Column>{gps}</Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("gps")}</b>
              </Column>
              <Column>
                <Input underline type="text" name="gps" register={register} />
              </Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("grid")}</b>
              </Column>
              <Column>
                <Input underline type="text" name="grid" register={register} />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("grid")}</b>
              </Column>
              <Column><a href={"https://www.grid.ac/institutes/" + grid}>{grid}</a></Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("wikidata")}</b>
              </Column>
              <Column>
                <Input underline type="text" name="wikidata" register={register} />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("wikidata")}</b>
              </Column>
              <Column><a href={"https://www.wikidata.org/wiki/" + wikidata}>{wikidata}</a></Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("gnd")}</b>
              </Column>
              <Column>
                <Input underline type="text" name="gnd" register={register} />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("gnd")}</b>
              </Column>
              <Column><a href={"http://d-nb.info/gnd/" + gnd}>{gnd}</a></Column>
            </Row>
          )}

          <Row>
            <Column attr>
              <b>{t("entity:permalink")}</b>
            </Column>
            <Column>{`${window.location.origin}/${pageEntity}s/${id}`}</Column>
          </Row>
          {isNew || isEdit ? (
            <Row noBorder marginTop>
              <Column attr></Column>
              <Column>
                <Link to={`/${pageEntity}s`}>
                  <Button light marginRight>
                    {t("entity:cancel")}
                  </Button>
                </Link>
                <Button type="submit">{t("entity:save")}</Button>
              </Column>
            </Row>
          ) : (
            <Row noBorder marginTop>
              <Column attr>
                <Link to={`/${pageEntity}s`}>
                  <Button>{t("entity:back")}</Button>
                </Link>
              </Column>
              <Column></Column>
            </Row>
          )}
        </form>
      ) : (
        <DotLoader
          size={70}
          color="#000"
          css={`
            margin: 20rem auto;
          `}
        />
      )}
      <Toast />
    </>
  );
};

const mapStateToProps = ({ institution }: StateFromProps): StateToProps => ({
  institution,
});

export default connect(mapStateToProps, {
  fetchInstitution,
})(InstitutionDetail);

interface StateFromProps {
  institution: InstitutionDataState;
}

interface StateToProps {
  institution: InstitutionDataState;
}
