import {
  FetchEntityRequestAction,
  FetchEntitySuccessAction,
  FetchEntityFailureAction,
  FETCH_ENTITY_REQUEST,
  FETCH_ENTITY_SUCCESS,
  FETCH_ENTITY_FAILURE,
} from "redux/types";
import { EntityDataState } from "common/types";

type ValidAction =
  | FetchEntityRequestAction
  | FetchEntitySuccessAction
  | FetchEntityFailureAction;

const entity = (state = { data: {} }, action: ValidAction): EntityDataState => {
  switch (action.type) {
    case FETCH_ENTITY_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_ENTITY_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_ENTITY_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default entity;
