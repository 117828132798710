import styled from "styled-components";

interface Row {
  margin?: boolean;
}
export const Row = styled.div<Row>`
  display: flex;
  /* align-items: center; */
  line-height: 1.6;
  margin: ${({ margin }) => (margin ? "0.5rem 0" : "0")};
`;
