import {
  FetchManuscriptsRequestAction,
  FetchManuscriptsSuccessAction,
  FetchManuscriptsFailureAction,
  FETCH_MANUSCRIPTS_REQUEST,
  FETCH_MANUSCRIPTS_SUCCESS,
  FETCH_MANUSCRIPTS_FAILURE,
} from "redux/types";
import { ManuscriptsDataState } from "common/types";

type ValidAction =
  | FetchManuscriptsRequestAction
  | FetchManuscriptsSuccessAction
  | FetchManuscriptsFailureAction;

const manuscripts = (
  state = { data: [] },
  action: ValidAction
): ManuscriptsDataState => {
  switch (action.type) {
    case FETCH_MANUSCRIPTS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_MANUSCRIPTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_MANUSCRIPTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default manuscripts;
