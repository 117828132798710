import { Person, Universal } from "common/types";

export const findPersonById = (id: string, persons: Person[]) => {
  return persons.filter((person) => person.id === id)[0];
};
export const findUniversalById = (id: string, universals: Universal[]) => {
  return universals.filter((universal) => universal.id === id)[0];
};
export const getDateTimeFromUtf = (utfDateTime: string) => {
  return `${new Date(utfDateTime).toLocaleString()}`;
};
