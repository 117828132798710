import React, { useEffect, useMemo, ReactNode } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { Cell, Row } from "react-table";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";

import { SearchForm, Table, PageTitle, LinkButton } from "components";
import {
  PersonsDataState,
  SearchFormData,
  ManuscriptsUniversalsDataState,
  PersonBinding,
} from "common/types";
import {
  fetchManuscriptsUniversals,
  // fetchPersons,
} from "redux/actions/entitiesActions";
// import { findPersonById } from "utils";

interface ManuscriptUniversalPage {
  manuscriptsUniversals: ManuscriptsUniversalsDataState;
  fetchManuscriptsUniversals: (formData?: SearchFormData) => void;
}

const pageEntity = "manuscriptsUniversal";

const ManuscriptUniversalPage: React.FC<ManuscriptUniversalPage> = ({
  manuscriptsUniversals,
  fetchManuscriptsUniversals,
  //persons,
  //fetchPersons,
}) => {
  const { t } = useTranslation(pageEntity);

  useEffect(() => {
    fetchManuscriptsUniversals();
  }, [fetchManuscriptsUniversals]);

  /*
  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);
  */
  const data = useMemo(
    () => (manuscriptsUniversals.isFetching ? [] : manuscriptsUniversals.data),
    [manuscriptsUniversals]
  );

  const sortByName = (a: Row, b: Row) => {
    return a.values.authors_id[0].name.localeCompare(
      b.values.authors_id[0].name
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: t("id"),
        accessor: "id",
        Cell: ({ row }: Cell): ReactNode => {
          const entityId = row.values.id;
          return (
            <Link to={`/universals/${entityId}`}>
              <LinkButton>{entityId.substring(0, 5)}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("manuscript_id"),
        accessor: "manuscript_id",
      },
      {
        Header: t("shelfmark"),
        accessor: "shelfmark",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <Link to={`/manuscripts/${row.values.manuscript_id}`}>
              <LinkButton>{row.values.shelfmark}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("sigla"),
        accessor: "sigla2",
      },
      {
        Header: t("authors"),
        accessor: "authors_id",
        sortType: sortByName,
        Cell: ({ row }: Cell): ReactNode => {
          const authorsId: PersonBinding[] = row.values.authors_id;
          return (
            <>
              {authorsId &&
                authorsId.map((author: PersonBinding, key: number) => {
                  // const author = findPersonById(authorId, persons.data);
                  return <p key={key}>{author.name}</p>;
                })}
            </>
          );
        },
        /*
          <p key={key}>
                        <Link to={`/persons/${author.id}`}>
                          <LinkButton>
                            {author.name}
                          </LinkButton>
                        </Link>
                        
                      </p>


        Header: t("authors"),
        accessor: "authors",
        Cell: ({ row }: Cell): ReactNode => {
          const authors = row.values.authors;

          return (
            <>
              {authors &&
                authors.map((authorId: string, key: number) => {
                  const author = findPersonById(authorId, persons.data);
                  return (
                    <p key={key}>
                      <Link to={`/persons/${authorId}`}>
                        <LinkButton>
                          {author ? author.name : authorId}
                        </LinkButton>
                      </Link>
                      {", "}
                    </p>
                  );
                })}
            </>
          );
        }, */
      },
      {
        Header: t("title"),
        accessor: "title",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <Link to={`/universals/${row.values.id}`}>
              <LinkButton>{row.values.title}</LinkButton>
            </Link>
          );
        },
      },
      {
        Header: t("origin"),
        accessor: "origin",
      },
      {
        Header: t("facsimile"),
        accessor: "facsimile",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <>
              {row.values.facsimile !== " " && (
                <a href={row.values.facsimile} target="_blank">
                  <FaExternalLinkAlt color="blue" />
                </a>
              )}
            </>
          );
        },
      },
      {
        Header: t("catalogue"),
        accessor: "digital_copy",
        Cell: ({ row }: Cell): ReactNode => {
          return (
            <>
              {row.values.digital_copy !== " " && (
                <a href={row.values.digital_copy} target="_blank">
                  <FaExternalLinkAlt color="blue" />
                </a>
              )}
            </>
          );
        },
      },
    ],
    [t]
  );

  const onSearchSubmit = (formData: SearchFormData): void => {
    fetchManuscriptsUniversals(formData);
  };

  return (
    <>
      <PageTitle>{t("pageTitle")}</PageTitle>
      <SearchForm
        attributes={["shelfmark", "sigla2", "author", "title", "origin"]}
        entity={pageEntity}
        onSearchSubmit={onSearchSubmit}
        onSearchReset={(): void => fetchManuscriptsUniversals()}
        readonly={manuscriptsUniversals.isFetching}
      />
      <Table
        data={data}
        columns={columns}
        showLoading={manuscriptsUniversals && manuscriptsUniversals.isFetching}
      />
    </>
  );
};

const mapStateToProps = ({
  manuscriptsUniversals,
}: // persons,
StateFromProps): StateToProps => ({
  manuscriptsUniversals,
  // persons,
});

export default connect(mapStateToProps, {
  fetchManuscriptsUniversals,
  // fetchPersons,
})(ManuscriptUniversalPage);

interface StateFromProps {
  manuscriptsUniversals: ManuscriptsUniversalsDataState;
  // persons: PersonsDataState;
}

interface StateToProps {
  manuscriptsUniversals: ManuscriptsUniversalsDataState;
  // persons: PersonsDataState;
}
