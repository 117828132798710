import {
  FetchManuscriptsUniversalsRequestAction,
  FetchManuscriptsUniversalsSuccessAction,
  FetchManuscriptsUniversalsFailureAction,
  FETCH_MANUSCRIPTS_UNIVERSALS_REQUEST,
  FETCH_MANUSCRIPTS_UNIVERSALS_SUCCESS,
  FETCH_MANUSCRIPTS_UNIVERSALS_FAILURE,
} from "redux/types";
import { ManuscriptsUniversalsDataState } from "common/types";

type ValidAction =
  | FetchManuscriptsUniversalsRequestAction
  | FetchManuscriptsUniversalsSuccessAction
  | FetchManuscriptsUniversalsFailureAction;

const manuscriptsUniversals = (
  state = { data: [] },
  action: ValidAction
): ManuscriptsUniversalsDataState => {
  switch (action.type) {
    case FETCH_MANUSCRIPTS_UNIVERSALS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_MANUSCRIPTS_UNIVERSALS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_MANUSCRIPTS_UNIVERSALS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default manuscriptsUniversals;
