import {
  FetchUniversalRequestAction,
  FetchUniversalSuccessAction,
  FetchUniversalFailureAction,
  FETCH_UNIVERSAL_REQUEST,
  FETCH_UNIVERSAL_SUCCESS,
  FETCH_UNIVERSAL_FAILURE,
} from "redux/types";
import { UniversalDataState } from "common/types";

const initUniversal = {
  id: "",
  sigla1: "",
  sigla2: "",
  authors_id: [],
  manuscripts: [],
  title: "",
  origin: "",
  incipit: "",
  notes: "",
  edition: "",
  edition_link: "",
  literature: "",
  facsimile: "",
  createdAt: "",
  updatedAt: "",
};

type ValidAction =
  | FetchUniversalRequestAction
  | FetchUniversalSuccessAction
  | FetchUniversalFailureAction;

const universal = (
  state = { data: initUniversal },
  action: ValidAction
): UniversalDataState => {
  switch (action.type) {
    case FETCH_UNIVERSAL_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case FETCH_UNIVERSAL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        error: null,
      };
    case FETCH_UNIVERSAL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default universal;
