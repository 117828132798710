import axios from "axios";
import store from "redux/store";

export const api = axios.create({
  baseURL: "https://uniq.flu.cas.cz/api/v1",  // "http://localhost:3000/api/v1", // 
  timeout: 6000,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${store.getState().token}`,
  },
});
