import React, { ReactNode } from "react";
import styled from "styled-components";

interface ModalWrap {
  showModal: boolean;
}
const ModalWrap = styled.div<ModalWrap>`
  display: ${({ showModal }) => (showModal ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 40;
`;
const Background = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.86);
`;
interface Modal {
  children?: ReactNode;
  onClose: Function;
  showModal: boolean;
}
export const Modal: React.FC<Modal> = ({ children, onClose, showModal }) => {
  return (
    <ModalWrap showModal={showModal}>
      <Background onClick={(): void => onClose()}></Background>
      {children}
    </ModalWrap>
  );
};

interface Card {
  fullwidth?: boolean;
}
const Card = styled.div<Card>`
  width: ${({ fullwidth }) => (fullwidth ? "calc(100vw - 40px)" : "50%")};
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  z-index: 50;
`;
interface ModalCard {
  children?: ReactNode;
  fullwidth?: boolean;
}
export const ModalCard: React.FC<ModalCard> = ({ children, fullwidth }) => {
  return <Card fullwidth={fullwidth}>{children}</Card>;
};

const CardHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: 20px;
  position: relative;
  background-color: #ffffff;
  border-bottom: 1px solid #dbdbdb;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;
const CardTitle = styled.h2`
  flex-grow: 1;
  flex-shrink: 0;
  font-size: 2rem;
  line-height: 1;
`;
interface ModalHeader {
  title?: string;
  onClose: Function;
}
export const ModalHeader: React.FC<ModalHeader> = ({ title, onClose }) => {
  return (
    <CardHeader>
      <CardTitle>{title}</CardTitle>
      <button
        type="button"
        onClick={(): void => onClose()}
        aria-label="close"
      ></button>
    </CardHeader>
  );
};

const CardBody = styled.section`
  -webkit-overflow-scrolling: touch;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
  padding: 5rem;
  background-color: #ffffff;
`;
interface ModalContent {
  children?: ReactNode;
}
export const ModalContent: React.FC<ModalContent> = ({ children }) => {
  return <CardBody>{children}</CardBody>;
};

const Footer = styled.div`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #dbdbdb;
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-shrink: 0;
  justify-content: flex-end;
  padding: 20px;
  position: relative;
`;
interface ModalFooter {
  children?: ReactNode;
}
export const ModalFooter: React.FC<ModalFooter> = ({ children }) => {
  return <Footer>{children}</Footer>;
};
