import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { PageTitle } from "components";
import { getPosts } from "api/getPosts";
import { Post } from "common/types";

const StyledPost = styled.div`
  margin-bottom: 1rem;
`;

const Paragraph = styled.div`
  margin-top: 1.5rem;
`

const H2 = styled.h2`
  margin-top: 2rem;
`
const ListMain = styled.ol`
  list-style-type: upper-roman;
  margin-top: 1.5rem;
`

const ListSub = styled.ol`
`

const ListItem = styled.li`
  margin-left: 2rem;
`

interface NewsPage {}
const NewsPage: React.FC<NewsPage> = () => {
  // const [news, setNews] = useState<Post[]>();
  // useEffect(() => {
  //  getPosts("news").then((data) => setNews(data));
  // }, []);

  return (
    <>
      <PageTitle>Sigla</PageTitle>
      <H2>Signs <i>(sigla)</i> used in the basic listing of the database</H2>
      <Paragraph>Advanced search and sorting of the texts in the UniQ database are enabled by an artificially created 
        system of <i>sigla</i> which classify the texts on universals by their types. 
        These <i>sigla</i> have been taken from Šmahel’s catalogue, but have significant 
        added value in the digital environment. The following <i>sigla</i> are assigned to the individual types of texts 
        so that, for example, under the <i>siglum</i> <b>VI.4</b>, the user will find all 
        the <i>varia opera et opuscula</i> from the field of metaphysics.</Paragraph>
      
      <ListMain>
        <ListItem>
          <b>Opera Philosophica Johannis Wyclif</b>
        </ListItem>
        <ListItem>
          <b>Opera et opuscula de universalibus</b>
          <ListSub>
            <ListItem>Tractatus – polemicae – disputabilia</ListItem>
            <ListItem>Quaestiones quodlibeticae</ListItem>
            <ListItem>Quaestiones doctrinales, dialecticae et temptativae</ListItem>
            <ListItem>Sophismata</ListItem>
            <ListItem>Notae et argumenta</ListItem>
            <ListItem>Conspectues et schemata</ListItem>
            <ListItem>Defensiones, recommendationes, sermones etc.</ListItem>
          </ListSub>
        </ListItem>
        <ListItem>
          <b>Expositiones et quaestiones in veterem artem</b>
          <ListSub>
            <ListItem>Introitus in veterem artem – <i>Isagogen</i> Porphyrii</ListItem>
            <ListItem>Expositiones et quaestiones in librum <i>Praedicamentorum</i> Aristotelis</ListItem>
            <ListItem><i>Liber sex principiorum</i> Gilberto Porretano ascriptum cum textu eiusdem: textus – quaestiones – glossae</ListItem>
            <ListItem><i>Liber divisionum</i> Boethii: textus   quaestiones, glossae</ListItem>
            <ListItem>Expositiones et quaestiones in I–II libros <i>De interpretatione</i> Aristotelis</ListItem>
          </ListSub>
        </ListItem>
        <ListItem>
          <b>Expositiones et quaestiones in logicam novam</b>
          <ListSub>
            <ListItem>Expositiones et quaestiones in I–II libros <i>Analyticorum priorum</i> Aristotelis</ListItem>
            <ListItem>Expositiones et quaestiones in I–II libros <i>Analyticorum posteriorum</i> Aristotelis</ListItem>
            <ListItem>Expositiones et quaestiones in I–VIII libros <i>Topicorum</i> Aristotelis</ListItem>
            <ListItem>Expositiones et quaestiones in I–II libros <i>De sophisticis elenchis</i> Aristotelis</ListItem>
          </ListSub>
        </ListItem>
        <ListItem>
          <b>Expositiones et quaestiones in philosophiam naturalem</b>
          <ListSub>
            <ListItem>Expositiones et quaestiones in I–VIII libros <i>Physicorum</i> Aristotelis</ListItem>
            <ListItem>Expositiones et quaestiones in I–II libros <i>De anima</i> Aristotelis</ListItem>
          </ListSub>
        </ListItem>
        <ListItem>
          <b>Expositiones et quaestiones in metaphysicam</b>
          <ListSub>
            <ListItem>Expositiones et quaestiones in I–XIII libros <i>Metaphysicae</i> Aristotelis</ListItem>
            <ListItem><i>Liber de causis</i> Pseudo-Aristotelis: textus – expositiones</ListItem>
            <ListItem><i>De esse et essentia</i>: theoremata – tractatus – expositiones – quaestiones,</ListItem>
            <ListItem>Varia opera et opuscula</ListItem>
          </ListSub>
        </ListItem>
      </ListMain> 

    </>
  );
};

export default NewsPage;
