import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ValueType, OptionTypeBase } from "react-select";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import DotLoader from "react-spinners/DotLoader";

import {
  UniversalDataState,
  Universal,
  PersonsDataState,
  UseParamsId,
  iOption,
} from "common/types";
import { fetchUniversal } from "redux/actions/entityActions";
import { fetchPersons } from "redux/actions/entitiesActions";
import {
  EntityDetailBlockHeading as BlockHeading,
  EntityDetailHeading as Heading,
  EntityDetailRow as Row,
  EntityDetailColumn as Column,
  Input,
  Button,
  DropDown,
  LinkButton,
  TextArea,
  Toast,
} from "components";
import { addEntity } from "api/addEntity";
import { updateEntity } from "api/updateEntity";
import { findPersonById, getDateTimeFromUtf } from "utils";
import { toast } from "react-toastify";

const AuthorsColumn = styled.div`
  line-height: 1.6;
`;

const ManuscriptsColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.4rem;
`;

export const Card = styled.div`
  border: 1px solid black;
  width: 43rem;
  margin: 0.5rem 0;
  padding: 1rem;
  border-radius: 0.3rem;
`;

const AttributeHeading = styled.h4`
  margin-right: 0.5rem;
  white-space: nowrap;
  width: 14rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  text-align: left;
  width: 100%;
  :visited {
    color: blue;
  }
`;

const StyledSpan = styled.span`
  text-align: left;
  width: 100%;
`;

const StyledALink = styled.a`
  /* text-decoration: none; */
  :visited {
    color: blue;
  }
`;

interface UniversalDetail {
  universal: UniversalDataState;
  fetchUniversal: (id: string) => void;
  fetchPersons: () => void;
  persons: PersonsDataState;
  isNew?: boolean;
  isEdit?: boolean;
}
type SelectedPerson = {
  value: string;
  label: string;
};
type FormData = {
  id: string;
  sigla1: string;
  sigla2: string;
  title: string;
  origin: string;
  // incipit: string;
  notes: string;
  edition: string;
  edition_link: string;
  literature: string;
};

const pageEntity = "universal";
const UniversalDetail: React.FC<UniversalDetail> = ({
  universal,
  fetchUniversal,
  fetchPersons,
  persons,
  isNew,
  isEdit,
}) => {
  const { t } = useTranslation(pageEntity);
  const history = useHistory();
  const { id } = useParams<UseParamsId>();
  const {
    sigla1,
    sigla2,
    authors_id,
    manuscripts,
    title,
    origin,
    // incipit,
    notes,
    edition,
    edition_link,
    literature,
    createdAt,
    updatedAt,
  } = universal.data;
  const { register, handleSubmit, setValue, reset } = useForm<FormData>();
  const [selectedPersons, setSelectedPersons] = useState<
    ValueType<OptionTypeBase>
  >(null);
  const [dropDownPersons, setDropDownPersons] = useState([{}]);

  useEffect(() => {
    fetchPersons();
  }, [fetchPersons]);

  useEffect(() => {
    if (!isNew && authors_id && authors_id.length > 0) {
      const authorsIdDrop = authors_id.map((author, key) => {
        const person = findPersonById(author.id, persons.data);
        return person ? { key: key, label: person.name, value: author } : {};
      });
      setSelectedPersons(authorsIdDrop);
    }
  }, [authors_id, isNew]);

  useEffect(() => {
    const dropPersons = persons.data.map((person, key) => {
      return { key: key, value: person.id, label: person.name };
    });
    setDropDownPersons(dropPersons);
  }, [persons.data]);
  

  useEffect(() => {
    if (!isNew) {
      fetchUniversal(id);
    }
  }, [fetchUniversal, id, isNew]);

  useEffect(() => {
    if (isNew) {
      reset();
      setValue("id", id);
      // setValue("sigla", "");
      // setValue("title", "");
      // setValue("origin", "");
      // setValue("incipit", "");
      // setValue("note", "");
    } else if (!universal.isFetching) {
      setValue("id", id);
      setValue("sigla1", sigla1);
      setValue("sigla2", sigla2);
      setValue("title", title);
      setValue("origin", origin);
      // setValue("incipit", incipit);
      setValue("notes", notes);
      setValue("edition", edition);
      setValue("edition_link", edition_link);
      setValue("literature", literature);
    }
  }, [
    setValue,
    isNew,
    id,
    sigla1,
    sigla2,
    title,
    origin,
    // incipit,
    notes,
    edition,
    edition_link,
    literature,
    universal.isFetching,
  ]);

  const onSubmit = ({
    sigla1,
    sigla2,
    title,
    origin,
    // incipit,
    notes,
    edition,
    edition_link,
    literature,
  }: FormData): void => {
    const universalObject = {
      id: id,
      sigla1: sigla1,
      sigla2: sigla2,
      authors_id: selectedPersons
        ? selectedPersons.map((selectedPerson: iOption) => selectedPerson.value)
        : [],
      title: title,
      origin: origin,
      // incipit: incipit,
      notes: notes,
      edition: edition,
      edition_link: edition_link,
      literature: literature,
    };
    if (isNew) {
      addEntity(pageEntity, universalObject as Universal).then(() => {
        history.push(`/${pageEntity}s/${id}/edit`);
        createNotify();
      });
    } else {
      updateEntity(pageEntity, universalObject as Universal).then(() =>
        saveNotify()
      );
    }
  };

  const createNotify = () => toast.dark(t("createSuccess"));
  const saveNotify = () => toast.dark(t("saveSuccess"));

  return (
    <>
      {universal && !universal.isFetching ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <BlockHeading>{t("detailTitle")}</BlockHeading>
          
          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("sigla1")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="sigla1"
                  register={register}
                  required
                />
              </Column>
            </Row>
          ) : (
            <Row></Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("sigla2")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="sigla2"
                  register={register}
                  required
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("sigla2")}</b>
              </Column>
              <Column>{sigla2}</Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("authors")}</b>
              </Column>
              <Column>
                <DropDown
                  isMulti
                  value={selectedPersons}
                  onChange={(selectedPersons: ValueType<OptionTypeBase>) =>
                    setSelectedPersons(selectedPersons)
                  }
                  options={dropDownPersons}
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("authors")}</b>
              </Column>
              <Column>
                <AuthorsColumn>
                  {authors_id &&
                    authors_id.map((person, key) => {
                      // const person = findPersonById(authorId, persons.data);
                      return (
                        <p key={key}>
                          <Link to={`/persons/${person.id}`}>
                            <LinkButton>
                              {person.name}
                            </LinkButton>
                          </Link>
                        </p>
                      );
                    })}
                </AuthorsColumn>
              </Column>
            </Row>
          )}
          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("title")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="title"
                  register={register}
                  required
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("title")}</b>
              </Column>
              <Column>{title}</Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("origin")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="origin"
                  register={register}
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("origin")}</b>
              </Column>
              <Column>{origin}</Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("notes")}</b>
              </Column>
              <Column>
                <TextArea name="notes" register={register} />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("notes")}</b>
              </Column>
              <Column>{notes}</Column>
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("edition")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="edition"
                  register={register}
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("edition")}</b>
              </Column>
              {edition_link != "" ? 
                (<Column>
                <StyledALink href={edition_link} target="_blank">
                  {edition}
                </StyledALink>
                </Column>) : 
                (<Column>{edition}</Column>)
              }
            </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("edition_link")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="edition_link"
                  register={register}
                />
              </Column>
            </Row>
          ) : (
            <Row></Row>
          )}

          {isNew || isEdit ? (
            <Row></Row>
          ) : (
            <Row>
            <Column attr>
              <b>{t("manuscripts")}</b>
            </Column>

            <ManuscriptsColumn>
              {manuscripts.length > 0 ? (
               manuscripts.map((manuscript, key) => {
                      // const person = findPersonById(authorId, persons.data);
                      return (
                        <p key={key}>
                          <Card>
                          <Row>
                            <AttributeHeading>{t("shelfmark")}</AttributeHeading>
                              <StyledLink to={`/manuscripts/${manuscript.manuscript_id}`}>
                              {manuscript.shelfmark}
                              </StyledLink>
                          </Row>
                          <Row>
                            <AttributeHeading>{t("foliation")}</AttributeHeading>
                              <StyledSpan>
                              {manuscript.foliation}
                              </StyledSpan>
                          </Row>
                          <Row>
                            <AttributeHeading>{t("origin")}</AttributeHeading>
                              <StyledSpan>
                              {manuscript.origin}
                              </StyledSpan>
                          </Row>
                          <Row>
                            <AttributeHeading>{t("incipit")}</AttributeHeading>
                              <StyledSpan>
                              {manuscript.incipit}
                              </StyledSpan>
                          </Row>
                          <Row>
                            <AttributeHeading>{t("scribe")}</AttributeHeading>
                            {manuscript.scribe_id != null ? 
                              <StyledLink to={`/persons/${manuscript.scribe_id}`}>
                                {manuscript.scribe_name}
                              </StyledLink> :
                              <StyledSpan>
                                {manuscript.scribe_name}
                              </StyledSpan>
                            }
                          </Row>
                          
                          </Card>
                        </p>
                      );
                    })
                ) : ("No manuscripts found.") }
                  
            {/*
              {currentUniversals &&
                currentUniversals.map((universal, key) => (
                  <ManuscriptDetailUniversalCard
                    key={key}
                    persons={persons}
                    manuscriptUniversalBinding={universal}
                    universals={universals}
                  />
                ))}
              */}
            </ManuscriptsColumn>
          </Row>
          )}

          {isNew || isEdit ? (
            <Row>
              <Column attr>
                <b>{t("literature")}</b>
              </Column>
              <Column>
                <Input
                  underline
                  type="text"
                  name="literature"
                  register={register}
                />
              </Column>
            </Row>
          ) : (
            <Row>
              <Column attr>
                <b>{t("literature")}</b>
              </Column>
              <Column>{literature}</Column>
            </Row>
          )}

          {isEdit && (
            <>
              <Row>
                <Column attr>
                  <b>{t("entity:created")}</b>
                </Column>
                <Column>{getDateTimeFromUtf(createdAt)}</Column>
              </Row>
              <Row>
                <Column attr>
                  <b>{t("entity:updated")}</b>
                </Column>
                <Column>{getDateTimeFromUtf(updatedAt)}</Column>
              </Row>
            </>
          )}
          <Row>
            <Column attr>
              <b>{t("entity:permalink")}</b>
            </Column>
            <Column>{`${window.location.origin}/${pageEntity}s/${id}`}</Column>
          </Row>
          {isNew || isEdit ? (
            <Row noBorder marginTop>
              <Column attr></Column>
              <Column>
                <Link to={`/${pageEntity}s`}>
                  <Button light marginRight>
                    {t("entity:cancel")}
                  </Button>
                </Link>
                <Button type="submit">{t("entity:save")}</Button>
              </Column>
            </Row>
          ) : (
            <Row noBorder marginTop>
              <Column attr>
                <Link to={`/${pageEntity}s`}>
                  <Button>{t("entity:back")}</Button>
                </Link>
              </Column>
              <Column></Column>
            </Row>
          )}
        </form>
      ) : (
        <DotLoader
          size={70}
          color="#000"
          css={`
            margin: 20rem auto;
          `}
        />
      )}
      <Toast />
    </>
  );
};

const mapStateToProps = ({
  universal,
  persons,
}: StateFromProps): StateToProps => ({
  universal,
  persons,
});

export default connect(mapStateToProps, {
  fetchUniversal,
  fetchPersons,
})(UniversalDetail);

interface StateFromProps {
  universal: UniversalDataState;
  persons: PersonsDataState;
}

interface StateToProps {
  universal: UniversalDataState;
  persons: PersonsDataState;
}
